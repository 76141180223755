<template>
  <v-container>
    <v-row>
      <v-col sm="auto">
        <slot name="input"></slot>
      </v-col>
      <v-col sm="auto">
        <div v-if="parseParts && showParsing" class="transparent parsing-window">
          <div
            v-for="(word, parstIndex) in parseParts"
            :key="parstIndex"
            class="ui"
          >
            <span :lang="parseText.lang">{{ word.word }}</span>
            <span
              v-if="word.hasParsing()"
              :lang="$i18n.locale"
            >
            <span
              v-html="' &mdash; ' + $t(i18nTag(majorParsing(word.parsing)))"
            ></span>
          </span>
            <span
              v-for="(p, parseIndex) in minorParsings1(word.parsing)"
              :key="'m1_' + parseIndex"
            ><span v-if="parseIndex === 0" v-html="': &nbsp; '"></span><span v-else>&ndash;</span><parsing-abbrev :parsing="p"></parsing-abbrev></span>
            <span
              v-for="(p, parseIndex) in minorParsings2(word.parsing)"
              :key="'m2_' + parseIndex"
            ><span v-if="parseIndex === 0 && minorParsings1(word.parsing).length === 0">:</span><span v-if="parseIndex === 0" v-html="' &nbsp; '"></span><span v-else>&ndash;</span><parsing-abbrev :parsing="p"></parsing-abbrev></span>
            <span v-if="word.root" :lang="word.rootLang"><span :lang="$i18n.locale"> {{ $t('text.from') }} </span>{{ word.root }}</span>
            <span
              v-if="word.hasParsing() && word.parsing.partOfSpeech2"
              :lang="$i18n.locale"
            > {{ $t('text.and') }} {{$t(i18nTag(word.parsing.partOfSpeech2))}}</span>
            <span v-if="word.strongs">
              <tipped-text tip="Strong's Number"> {{ word.strongs.strongsNum() }}</tipped-text>
            </span>
          </div>
        </div>
        <div v-if="parseParts && showWordInfo" class="transparent parsing-window">
          <div
            v-for="(part, index) in parseParts"
            :key="index"
          >
            {{ part.word }}: {{ part.wordId }}, {{ part.seqNum}}.{{ part.partNum }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-container
      v-if="value && value.rows"
    >
      <v-row>
        <slot name="heading"></slot>
      </v-row>
      <v-row>
        <v-col
          v-for="heading in value.headings"
          :key="heading.workId"
          sm
          align="center"
          class="ui heading-column transparent"
          :lang="$i18n.locale"
        >
          {{ $t(i18WorkTag(heading.workId)) }}
        </v-col>
      </v-row>
      <v-container
        v-for="(row, rowIndex) in value.rows"
        :key="rowIndex"
      >
        <v-row
          v-if="verseHeaders"
        >
          <v-col>
            <h2>{{ row.reference.human() }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(text, textIndex) in row.texts"
            :key="textIndex"
            :lang="columnLang(textIndex)"
            class="passage-column transparent"
            sm
          ><text-row
            :text-index="textIndex"
            :text="text"
            :row="row"
            @parse="onParse"
          ></text-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-row v-else>
      <v-col>
        <v-skeleton-loader class="transparent" type="text@6"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader class="transparent" type="text@6"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader class="transparent" type="text@6"></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader class="transparent" type="text@6"></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Notifications from '../lib/mixins/Notifications'
import TippedText from '../components/TippedText'
import ParsingMixin from '../mixins/ParsingMixin'
import ParsingAbbrev from '../components/ParsingAbbrev'
import { ParsingModel } from '../models/parsing-model'
import TextRow from '../components/TextRow'
import { QueryPassageResults } from '../models/query-passage-results'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Polyglot',
  components: { TextRow, ParsingAbbrev, TippedText },
  mixins: [Notifications, ParsingMixin],
  props: {
    value: {
      type: QueryPassageResults,
      required: false
    },
    verseHeaders: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      on: false,
      showParsing: false,
      clickedWord: null,
      parseText: null,
      showWordInfo: false,
      wordInfo: null
    }
  },
  watch: {
  },
  methods: {
    space (text, index) {
      if (text.words.list[index].partNum > 1 || (index > 0 && text.words.list[index - 1].puncAfter === '־')) {
        return ''
      }
      return ' '
    },
    columnLang (index) {
      return this.value.headings.list[index].lang
    },
    trapEnter (event) {
      if (event.keyCode === 13) {
        this.onLookup()
      }
    },
    onParse (parts, text, event) {
      if (event.altKey) {
        this.clickedWord = parts
        this.parseText = text
        this.showParsing = false
        this.showWordInfo = true
      } else if (this.partsHaveParsing(parts)) {
        this.clickedWord = parts
        this.parseText = text
        this.showWordInfo = false
        this.showParsing = true
      }
    },
    strongsNum (parts) {
      for (const part of parts) {
        if (part.strongs) {
          return part.strongs.strongsNum()
        }
      }
      return ''
    },
    majorParsing (p) {
      if (p.partOfSpeech) {
        return p.partOfSpeech
      } else if (p.modifier) {
        return p.modifier
      }
    },
    minorParsings (p) {
      const parsings = []
      if (p instanceof ParsingModel) {
        if (p.stem) {
          parsings.push(p.stem)
        }
        if (p.aspect && !p.isParticiple()) {
          parsings.push(p.aspect)
        }
        if (p.tense) {
          parsings.push(p.tense)
        }
        if (p.voice) {
          parsings.push(p.voice)
        }
        if (p.mood && !p.isParticiple()) {
          parsings.push(p.mood)
        }
        if (p.case) {
          parsings.push(p.case)
        }
        if (p.person) {
          parsings.push(p.person)
        }
        if (p.gender) {
          parsings.push(p.gender)
        }
        if (p.number) {
          parsings.push(p.number)
        }
        if (p.state) {
          parsings.push(p.state)
        }
      }
      return parsings
    },
    minorParsings1 (p) {
      const parsings = []
      if (p instanceof ParsingModel) {
        if (p.stem) {
          parsings.push(p.stem)
        }
        if (p.aspect && !p.isParticiple()) {
          parsings.push(p.aspect)
        }
        if (p.tense) {
          parsings.push(p.tense)
        }
        if (p.voice) {
          parsings.push(p.voice)
        }
        if (p.mood && !p.isParticiple()) {
          parsings.push(p.mood)
        }
      }
      return parsings
    },
    minorParsings2 (p) {
      const parsings = []
      if (p instanceof ParsingModel) {
        if (p.case) {
          parsings.push(p.case)
        }
        if (p.person) {
          parsings.push(p.person)
        }
        if (p.gender) {
          parsings.push(p.gender)
        }
        if (p.number) {
          parsings.push(p.number)
        }
        if (p.state) {
          parsings.push(p.state)
        }
      }
      return parsings
    }
  },
  computed: {
    parseParts () {
      const parts = []
      if (this.clickedWord) {
        for (const wp of this.clickedWord) {
          parts.push(wp)
        }
      }
      return parts
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .passage-column {
  }

  .heading-column {
    text-decoration: underline;
    font-weight: bold;
  }
  .reference-column {
    font-family: Scheherazade, sans-serif;
    direction: rtl;
  }

  .transparent {
    background-color: rgba(255,255,255,0.6) !important;
    border-radius: 5px;
  }

  h1 {
    font-size: 2.5rem;
  }

  .lookup-field {
    padding: 20px 10px 0;
  }

  .parsing-window {
    padding-left: 20px;
    padding-right: 20px;
  }
</style>
