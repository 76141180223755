<template>
  <v-app>
    <v-navigation-drawer
      temporary
      v-model="drawer"
      :mini-variant="mini"
      light
      app
      :right="$store.getters.lang === 'ar'"
    >
      <v-toolbar
        text
        class="transparent"
        short
      >
        <v-list class="pa-0">
          <v-list-item link>
            <a href="https://ta3lim-ar.org">
              <v-list-item-avatar>
                <v-img src="./assets/logo-small.png"></v-img>
              </v-list-item-avatar>
            </a>
            <v-list-item-content>
              <v-list-item-title>{{ $t('site.name') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-toolbar>
      <v-menu>
        <template v-slot:activator="{ on: menu }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn text
                     v-on="{ ...tooltip, ...menu }"
              >
                <span class="nav-text">{{ $t('nav.uiLang') }}</span>
              </v-btn>
            </template>
            <span>{{ $t('tooltip.setLanguage') }}</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(lang, index) in langs"
            :key="index"
            @click="setLanguage(lang.code)"
          >
            <v-list-item-title><span class="nav-text">{{ lang.title }}</span></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-list class="pt-0" dense>
        <v-divider></v-divider>
        <v-list-item v-if="$store.getters.isAuthenticated" link @click="logout()">
          <v-list-item-content>
            <v-list-item-title><span class="nav-text">{{ $t('nav.logout') }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="morphologicalSearch()">
          <v-list-item-content>
            <v-list-item-title class="nav-text" id="txt"><span class="nav-text">{{ $t('nav.morphologicalSearch') }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="strongsPage()">
          <v-list-item-content>
            <v-list-item-title class="nav-text"><span class="nav-text">{{ $t('nav.strongsPage') }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="strongsSearch()">
          <v-list-item-content>
            <v-list-item-title class="nav-text"><span class="nav-text">{{ $t('nav.strongsSearch') }}</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      collapse-on-scroll
      color="#598377"
      dark
      height="80"
    >
      <img
        height="60"
        width="60"
        src="./assets/logo-small.png"
        @click="toggleDrawer()"
        alt="logo"
      />
      <v-toolbar-title
        @click="$router.push({ name: 'home' })"
        link
        class="site-name"
      >
        <v-img src="./assets/polyglot-80.png" height="60"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
<!--        <v-list-item :to="{ name: 'words-for-review' }">{{ $t('nav.wordsForReview') }}</v-list-item>-->
<!--        <v-list-item :to="{ name: 'words-index' }">{{ $t('nav.wordsIndex') }}</v-list-item>-->
        <v-list-item>{{ userName }}</v-list-item>
      </v-toolbar-items>
    </v-app-bar>
      <v-main class="bg-image">
        <router-view></router-view>
        <notifications
          :lang="$i18n.locale"
        ></notifications>
      </v-main>
  </v-app>
</template>

<script>

import User from './lib/mixins/User'
import Notifications from './lib/components/Notifications'

export default {
  name: 'App',
  components: {
    Notifications
  },
  mixins: [User],
  data: () => ({
    drawer: false,
    mini: false,
    langs: [
      {
        code: 'en',
        title: 'English'
      },
      {
        code: 'ar',
        title: 'عربي'
      }
    ]
  }),
  methods: {
    setLanguage (lang) {
      // eslint-disable-next-line no-console
      // console.log(lang);
      this.$store.commit('setLanguage', lang)
      this.$vuetify.rtl = (lang === 'ar')
      this.setUserLanguage(lang)
      this.drawer = false
    },
    toggleDrawer () {
      this.drawer = !this.drawer
    },
    logout () {
      this.logoutUser()
      this.drawer = false
      this.$router.push({ name: 'home' })
    },
    morphologicalSearch () {
      this.$router.push({ name: 'hebrew-search' })
    },
    strongsPage () {
      this.$router.push({ name: 'strongs-page' })
    },
    strongsSearch () {
      this.$router.push({ name: 'strongs-search' })
    }
  },
  mounted () {
    if (this.$store.getters.lang) {
      this.setLanguage(this.$store.getters.lang)
    }
  }
}
</script>

<style lang="scss">
  @import './assets/biblical-fonts/sbl-hebrew.css';
  @import './assets/biblical-fonts/sbl-greek.css';
  @import './assets/biblical-fonts/scheherazade.css';
  @import url('https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

  $arabic-ui-font: "Markazi Text", "Roboto", sans-serif;
  $english-ui-font: "Roboto", sans-serif;
  $greek-text-font: "SBL Greek", sans-serif;
  $hebrew-text-font: "SBL Hebrew", sans-serif;
  $arabic-text-font: "Scheherazade", "Times New Roman", sans-serif;
  $english-text-font: "Times New Roman", serif;

  [lang="he"] {
    font-family: $hebrew-text-font;
    font-size: 1.3rem;
    direction: rtl;
  }

  [lang="ar"] {
    font-family: $arabic-text-font;
    font-size: 1.5rem;
    direction: rtl;
  }

  [lang="gr"] {
    font-family: $greek-text-font;
    font-size: 1.2rem;
    direction: ltr;
  }

  [lang="en"] {
    font-family: $english-text-font;
    direction: ltr;
  }

  input[lang="ar"], .ui[lang="ar"] {
    font-family: $arabic-ui-font;
    font-size: 1.5rem;
    direction: rtl;
  }

  input[lang="en"], .ui[lang="en"] {
    font-family: $english-ui-font;
    direction: ltr;
  }

  .bg-image {
    /* The image used */
    background-image: url("./assets/papyrus2-background.jpg");

    /* Add the blur effect */
    /*filter: blur(8px);*/
    /*-webkit-filter: blur(8px);*/

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  div.nav-text {
    line-height: 1.3rem !important;
    font-size: 1.2rem !important;
  }
</style>
