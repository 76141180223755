<template>
  <v-snackbar
    v-model="show"
    bottom
    :color="color"
    :lang="$i18n.locale"
    :timeout="10000"
    multi-line
  >
      <span>
        <span class="notification-title">
          {{ title }}
        </span>
        &mdash;
        <span class="notification-message">
          {{ message }}
        </span>
      </span>
    <v-btn
      text
      @click="onClose()"
      class="close-button"
    ><v-icon>mdi-close</v-icon></v-btn>
  </v-snackbar>

</template>

<script>
export default {
  name: 'NotifyPopup',
  props: {
    message: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  },
  watch: {
    value (newVal, oldVal) {
      this.show = newVal
    },
    show (newVal, oldVal) {
      if (!newVal) {
        this.$emit('input', false)
      }
    }
  },
  methods: {
    onClose () {
      this.show = false
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
  .notification-title {
    /*text-align: center;*/
    font-size: 140%;
    font-weight: bold;
  }
  .notification-message {
    /*text-align: center;*/
    font-size: 140%;
  }
  .close-button {
    float: left;
  }
</style>
