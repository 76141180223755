<template>
  <v-container>
    <notify-popup
      v-model="showWarning"
      color="#FF8C00"
      :title="$t('title.warning')"
      :message="warningMessage"
    ></notify-popup>
    <notify-popup
      v-model="showError"
      color="#8B0000"
      :title="$t('title.error')"
      :message="errorMessage"
    ></notify-popup>
    <notify-popup
      v-model="showSuccess"
      color="#006400"
      :title="$t('title.success')"
      :message="successMessage"
    ></notify-popup>
  </v-container>
</template>

<script>
import NotifyPopup from './NotifyPopup'
export default {
  name: 'NotificationsBox',
  components: { NotifyPopup },
  data () {
    return {
      showError: false,
      showSuccess: false,
      showWarning: false
    }
  },
  computed: {
    showErrorNotification () {
      return this.$store.getters.showErrorNotification
    },
    errorMessage () {
      return this.$store.getters.errorMessage
    },
    showSuccessNotification () {
      return this.$store.getters.showSuccessNotification
    },
    successMessage () {
      return this.$store.getters.successMessage
    },
    showWarningNotification () {
      return this.$store.getters.showWarningNotification
    },
    warningMessage () {
      return this.$store.getters.warningMessage
    }
  },
  watch: {
    showErrorNotification (show) {
      if (show) {
        this.showWarning = false
        this.showSuccess = false
        this.showError = true
        this.$store.commit('showErrorNotification', false)
      }
    },
    showSuccessNotification (show) {
      if (show) {
        this.showWarning = false
        this.showError = false
        this.showSuccess = true
        this.$store.commit('showSuccessNotification', false)
      }
    },
    showWarningNotification (show) {
      if (show) {
        this.showSuccess = false
        this.showError = false
        this.showWarning = true
        this.$store.commit('showWarningNotification', false)
      }
    }
  }
}
</script>

<style scoped>

</style>
