import store from '../store'

// eslint-disable-next-line camelcase
export function get_default_headers () {
  const headers = {}

  if (store.getters.jwt) { headers.Authorization = 'JWT ' + store.getters.jwt }

  // if (component && component.$cookies.get('csrftoken'))
  //   headers['X-CSRFToken'] = component.$cookies.get('csrftoken');

  // eslint-disable-next-line no-console
  // console.log(headers)
  return headers
}
