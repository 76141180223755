import { BaseModel } from '../lib/model'

export class ParsingModel extends BaseModel {
  partOfSpeech;
  partOfSpeech2;
  person;
  gender;
  number;
  tense;
  voice;
  mood;
  case;
  state;
  aspect;
  stem;
  modifier;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isParticiple () {
    return (this.partOfSpeech && this.partOfSpeech.search('PARTICIPLE_') !== -1)
  }

  isAlsoDefinite () {
    return this.partOfSpeech2 === 'POS_DEF_ARTICLE'
  }
}
