
export default {
  state: {
    errorMessage: '',
    successMessage: '',
    warningMessage: '',
    showErrorNotification: false,
    showSuccessNotification: false,
    showWarningNotification: false
  },
  getters: {
    errorMessage (state) {
      return state.errorMessage
    },
    successMessage (state) {
      return state.successMessage
    },
    warningMessage (state) {
      return state.warningMessage
    },
    showErrorNotification (state) {
      return state.showErrorNotification
    },
    showSuccessNotification (state) {
      return state.showSuccessNotification
    },
    showWarningNotification (state) {
      return state.showWarningNotification
    }
  },
  mutations: {
    showSuccessNotification (state, show) {
      if (typeof show === 'undefined') {
        show = true
      }
      state.showSuccessNotification = show
    },
    showErrorNotification (state, show) {
      if (typeof show === 'undefined') {
        show = true
      }
      state.showErrorNotification = show
    },
    showWarningNotification (state, show) {
      if (typeof show === 'undefined') {
        show = true
      }
      state.showWarningNotification = show
    },
    errorMessage (state, message) {
      state.errorMessage = message
    },
    successMessage (state, message) {
      state.successMessage = message
    },
    warningMessage (state, message) {
      state.warningMessage = message
    }
  },
  actions: {
    notifyError ({ commit, state }, message) {
      commit('errorMessage', message)
      commit('showErrorNotification', true)
    },
    notifySuccess ({ commit, state }, message) {
      commit('successMessage', message)
      commit('showSuccessNotification', true)
    },
    notifyWarning ({ commit, state }, message) {
      commit('warningMessage', message)
      commit('showWarningNotification', true)
    }
  }
}
