import i18n from '@/i18n'
import vuetify from '@/plugins/vuetify'
import { getUserInfo, tokenUserInfo } from '../authentication'
import { getLanguage } from '../funclib'
import axios from 'axios'
import router from '@/router'
const jwtDecode = require('jwt-decode')

export default {
  state: {
    jwt: localStorage.getItem('jwt'),
    lang: getLanguage(),
    userInfo: tokenUserInfo(localStorage.getItem('jwt'))
  },
  getters: {
    isAuthenticated: state => {
      return (state.jwt != null)
    },
    jwt: state => {
      return state.jwt
    },
    lang: state => {
      return state.lang
    },
    userInfo: state => {
      return state.userInfo
    }
  },
  mutations: {
    updateToken (state, newToken) {
      if (newToken) {
        const tokenInfo = tokenUserInfo(newToken)
        if (tokenInfo.id !== 'None') {
          localStorage.setItem('jwt', newToken)
          state.jwt = newToken
          state.userInfo = tokenInfo
          // eslint-disable-next-line no-console
          // console.log('token info:', tokenInfo)
          getUserInfo(state.userInfo.id)
        }
      }
    },
    removeToken (state) {
      localStorage.removeItem('jwt')
      state.jwt = null
      state.userInfo = null
    },
    setLanguage (state, lang) {
      localStorage.setItem('lang', lang)
      state.lang = lang
      i18n.locale = lang
      vuetify.rtl = (lang === 'ar')
    },
    updateUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    getUserInfo: context => {
      if (context.state.userInfo != null && context.state.userInfo.id) {
        getUserInfo(context.state.userInfo.id)
      }
    },
    obtainToken (context, { username, password }) {
      const payload = {
        username: username,
        password: password
      }
      axios.post(
        context.state.endpoints.obtainJWT,
        payload,
        {
          // headers: {
          //   'Access-Control-Allow-Origin': '*',
          //   'Access-Control-Allow-Headers': 'access-control-allow-origin'
          // }
        }
      ).then((response) => {
        context.commit('updateToken', response.data.token)
        // eslint-disable-next-line no-console
        console.log('redirecting to home')
        router.push({ name: 'home' })
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
    },
    refreshToken (context) {
      const payload = {
        token: context.state.jwt
      }
      axios.post(context.state.endpoints.refreshJWT, payload)
        .then((response) => {
          context.commit('updateToken', response.data.token)
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
        })
    },
    inspectToken (context) {
      const token = context.state.jwt
      if (token) {
        const decoded = jwtDecode(token)
        const exp = decoded.exp
        const origIat = decoded.orig_iat
        if (exp - (Date.now() / 1000) < 1800 && (Date.now() / 1000) - origIat < 628200) {
          context.dispatch('refreshToken')
        } else if (exp - (Date.now() / 1000) < 1800) {
          // DO NOTHING, DO NOT REFRESH
        } else {
          // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
          context.commit('removeToken')
        }
      }
    }
  }
}
