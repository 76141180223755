<template>
<v-container>
    <v-simple-table
    >
        <tbody
                v-for="(category, index) in categories" :key="index"
        >
            <tr>
                <th colspan="5" class="category-head">
                    {{ categoryNamePlural('ar', category) }}
                    &mdash;
                    {{ categoryNamePlural('en', category) }}
                </th>
            </tr>
            <tr
              class="heading-bottom"
            >
                <th lang="ar">اسم</th>
                <th lang="ar">اختصار</th>
<!--                <th lang="en" class="center">Strong's<br/>Counts</th>-->
                <th lang="en" class="center">Database<br/>Counts</th>
                <th lang="en">Abbrev</th>
                <th lang="en">Name</th>
            </tr>
            <tr
                    v-for="(value, key) in getTagsMaster(category)" :key="key"
            >
                <td lang="ar">{{ getParsing(parsings['ar'][category], key) }}</td>
                <td lang="ar">{{ getParsing(parsings['ar'][category]['abbrev'], key) }}</td>
<!--                <td lang="en" class="center">{{ slugCounts.getTagCount(category + '.' + key) }}</td>-->
                <td lang="en" class="center">{{ pgSlugCounts.getTagCount(category + '.' + key) }}</td>
                <td lang="en">{{ getParsing(parsings['en'][category]['abbrev'], key) }}</td>
                <td lang="en">{{ value }}</td>
            </tr>
        </tbody>
    </v-simple-table>
</v-container>
</template>

<script>
import parsingsAr from '../parsings/ar.json'
import parsingsEn from '../parsings/en.json'
import { ParsingSlugCountsModel } from '../models/ParsingSlugCounts'
import pgCounts from '../pg-slug-counts.json'
import counts from '../slug-counts.json'
import slugs2i18n from '../slugs2i18n'
export default {
  name: 'ParsingTagsView',
  props: {
    category: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      parsings: {
        ar: parsingsAr,
        en: parsingsEn
      },
      slugCounts: new ParsingSlugCountsModel(counts),
      pgSlugCounts: new ParsingSlugCountsModel(pgCounts)
    }
  },
  methods: {
    getTagsMaster (category) {
      const tags = {}
      for (const key in this.parsings.en[category]) {
        if (key !== 'name' && key !== 'namePlural' && key !== 'abbrev' && this.slugCounts.getTagCount(category + '.' + key) !== null) {
          tags[key] = this.parsings.en[category][key]
        }
      }
      return tags
    },
    categoryNamePlural (lang, category) {
      // console.log('lang:', lang, 'category:', category);
      // eslint-disable-next-line no-prototype-builtins
      if (this.parsings[lang][category].hasOwnProperty('namePlural')) {
        return this.parsings[lang][category].namePlural
      }
      return this.parsings[lang][category].name
    },
    getParsing (list, key) {
      if (list && list[key]) {
        return list[key]
      }
      return '-'
    }
  },
  computed: {
    categories () {
      if (this.category) {
        return this.category
      }
      return ['pos', 'person', 'gender', 'number', 'state', 'modifier', 'aspect', 'stemHebrew']
    }
  },
  mounted () {
    // this.slugCounts.api.fetch();
    // eslint-disable-next-line no-unused-vars
    for (const index in counts) {
      const slug = counts[index].slug
      // eslint-disable-next-line no-prototype-builtins
      if (!slugs2i18n.hasOwnProperty(slug)) {
        console.log(slug)
      }
    }
    for (const index in pgCounts) {
      const slug = pgCounts[index].slug
      // eslint-disable-next-line no-prototype-builtins
      if (!slugs2i18n.hasOwnProperty(slug)) {
        console.log(slug)
      }
    }
  }
}
</script>

<style scoped>
    th {
        background-color: lightgray;
        border-bottom: 0;
    }
    th.category-head {
        text-align: center;
        font-size: 150%;
        font-weight: bold;
        /*background-color: white;*/
        margin-top: 1.5em !important;
      border-bottom: 0 !important;
    }
    th[lang='ar'] {
        font-size: 110%;
    }
    td[lang='ar'] {
        font-size: 130%;
    }
    th[lang='en'] {
        font-size: 110%;
        text-align: left;
    }

    td[lang='en'] {
        font-size: 110%;
        text-align: left;
    }

    .center[lang="en"] {
        text-align: center;
    }

  tr.heading-bottom th {
    border-bottom: 1px black !important;
    border-top: 0;
  }
</style>
