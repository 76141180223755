import { BaseListModel, BaseModel } from '../lib/model'
import { WordListModel } from './word-model'
import { BibleReference } from '../classes/bible-reference'

export class StrongsEntryModel extends BaseModel {
  verseId;
  wordIds;
  words;
  fragment;
  origFragment;
  reference;

  constructor (data) {
    super()
    this.modelCopy(data)

    if (this.fragment instanceof Array) {
      // console.log(this.rows)
      this.fragment = new WordListModel(this.fragment)
    }
    if (this.origFragment instanceof Array) {
      // console.log(this.rows)
      this.origFragment = new WordListModel(this.origFragment)
    }

    if (this.reference) {
      this.reference = new BibleReference(this.reference)
    }
  }
}

export class StrongsEntryListModel extends BaseListModel {
  _class = StrongsEntryModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
