<template>
<v-container>
  <v-row>
    <v-col sm="auto" class="transparent">
      <v-text-field
        id="root"
        :label="$t('field.strongsNum')"
        v-model="strongsNum"
        v-on:keyup="trapEnter"
        :lang="$i18n.locale"
        class="ui transparent lookup-field"
      />
    </v-col>
    <v-col sm="auto" class="transparent">
      <div class="button-group">
        <v-btn
          :color="buttonPrimaryColor"
          @click="lookup()"
          class="lookup-button"
        >
          {{ $t('action.go') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
    <v-row v-if="query.strongs">
      <v-col class="transparent">
        <h1>{{ query.strongs.strongsNum}}</h1>
        <h2>{{ $t('title.forms') }}</h2>
        <div>
          <span v-for="(word, index) in query.strongs.words.list" :key="index" :lang="word.lang">
            <span v-if="index > 0"> &ndash; </span>
            {{ word.strongsWord }} <span :lang="query.strongs.lang">({{ word.transliteration }})</span>
          </span>
        </div>
        <h2>{{ $t('title.translations') }}</h2>
        <div>
          <span v-for="(word, index) in query.strongs.meanings.list" :key="index" :lang="query.strongs.lang">
            <span v-if="index > 0"> &ndash; </span>
            {{ word.meaning }}
          </span>
        </div>
      </v-col>
    </v-row>
 <v-row><v-col><p></p></v-col></v-row>
  <polyglot-snack v-model="query.strongs.polyglot" v-if="query.strongs" :verse-headers="true">
    <template #heading>
      <h2>{{ $t('title.references') }} ({{ query.strongs.polyglot.rows.length}})</h2>
    </template>
  </polyglot-snack>
</v-container>
</template>

<script>
import Colors from '../lib/mixins/Colors'
import { QueryModel } from '../models/query-model'
import Notifications from '../lib/mixins/Notifications'
import PolyglotSnack from '../components/PolyglotSnack'

export default {
  name: 'StrongsPage',
  components: { PolyglotSnack },
  mixins: [Colors, Notifications],
  props: {
    strongs: {
      type: String,
      default: 'H4551'
    },
    workId: {
      type: String,
      default: 'ARABIC-WORD'
    }
  },
  data () {
    return {
      query: new QueryModel(),
      strongsNum: 'H4551'
    }
  },
  methods: {
    trapEnter (event) {
      if (event.keyCode === 13) {
        this.lookup()
      }
    },
    lookup () {
      if (this.strongsNum) {
        const that = this
        this.query.api.queryStrongs(this.strongsNum, this.workId).then((response) => {
          // console.log('polyglot loaded!')
          if (that.query.strongs) {
            that.notifySuccess(that.$t('notify.passageLoaded'))
          } else {
            // if there are no rows then the verse reference was bad
            that.notifyError(that.$t('notify.badReference'))
            // that.notifyError('No Rows!')
          }
        }).catch((e) => {
          console.log(e)
          that.notifyError(that.$t('notify.passageFailedToLoad'))
        })
      } else {
        // console.log('bad reference!')
        this.notifyError(this.$t('notify.badReference'))
      }
    },
    wordClass (word) {
      let classes = ''
      if (word.highlighted) {
        classes += ' highlighted'
      }
      return classes
    }
  }
}
</script>

<style scoped>
.transparent {
  background-color: rgba(255,255,255,0.6) !important;
  border-radius: 5px;
}

h1 {
  font-size: 2.5rem;
}

.lookup-field {
  padding: 20px 10px 0;
}

.highlighted {
  background-color: greenyellow;
}
</style>
