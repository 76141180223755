<template>
  <v-container>
    <polyglot-snack v-model="query.polyglot">
      <template #input>
        <v-row>
          <v-col sm="auto">
            <v-text-field
              :label="$t('field.passage')"
              v-model="lookupText"
              v-on:keyup="trapEnter"
              :lang="$i18n.locale"
              class="ui transparent lookup-field"
            >
            </v-text-field>
          </v-col>
          <v-col sm="auto">
            <v-btn
              @click="onLookup()"
              class="ui"
            >{{ $t('action.go') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #heading>
        <h1
          :lang="$i18n.locale"
          class="ui"
        >{{ passageDisplayed }}</h1>
      </template>
    </polyglot-snack>
  </v-container>
</template>

<script>
import { QueryModel } from '../models/query-model'
import { BiblePassage } from '../classes/bible-reference'
import Notifications from '../lib/mixins/Notifications'
import PolyglotSnack from '../components/PolyglotSnack'

export default {
  name: 'PassageView',
  components: { PolyglotSnack },
  mixins: [Notifications],
  props: {
    passage: {
      type: BiblePassage,
      required: false
    }
  },
  data () {
    return {
      query: new QueryModel(),
      lookupText: '',
      lookupPassage: null,
      on: false
    }
  },
  watch: {
    lookupText (value) {
      this.lookupPassage = new BiblePassage(value)
    }
  },
  methods: {
    trapEnter (event) {
      if (event.keyCode === 13) {
        this.onLookup()
      }
    },
    onLookup () {
      const ref = new BiblePassage(this.lookupText)
      if (ref.hasBook() && ref.hasChapter() && ref.hasVerse()) {
        const that = this
        this.query.api.queryPassage(ref).then((response) => {
          // console.log('polyglot loaded!')
          if (that.query.polyglot.rows.length) {
            if (that.query.moreResults) {
              that.notifyWarning(that.$t('notify.partialResults'))
              ref.decode(that.query.passage.encode())
            } else {
              that.notifySuccess(that.$t('notify.passageLoaded'))
            }
            that.lookupText = ref.abbrevHuman()
            that.$store.commit('setLastPassage', ref)
          } else {
            // if there are no rows then the verse reference was bad
            that.notifyError(that.$t('notify.badReference'))
            // that.notifyError('No Rows!')
          }
        }).catch((e) => {
          console.log(e)
          that.notifyError(that.$t('notify.passageFailedToLoad'))
        })
      } else {
        // console.log('bad reference!')
        if (ref.status()) {
          this.notifyError(ref.status())
        } else {
          this.notifyError(this.$t('notify.badReference'))
        }
      }
    }
  },
  computed: {
    passageDisplayed () {
      // console.log('filter:', filter)
      if (this.query.passage) {
        return this.query.passage.humanCompact()
      }
      return null
    }
  },
  mounted () {
    if (this.passage) {
      this.lookupPassage = this.passage
    } else {
      this.lookupPassage = this.$store.getters.lastPassage
    }
    this.query.api.queryPassage(this.lookupPassage)
  }
}
</script>

<style scoped>
  .transparent {
    background-color: rgba(255,255,255,0.6) !important;
    border-radius: 5px;
  }

  h1 {
    font-size: 2.5rem;
  }

  .lookup-field {
    padding: 20px 10px 0;
  }
</style>
