<template>
  <v-row v-if="value.session && (value.session.moreResults || value.session.offset())">
    <v-col sm="1">
      <v-btn v-if="value.session.offset()" @click="$emit('previous')" class="ui">{{ $t('action.previous') }}</v-btn>
    </v-col>
    <v-col sm="10"></v-col>
    <v-col sm="1">
      <v-btn v-if="value.session.moreResults" @click="$emit('next')" class="ui">{{ $t('action.next') }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { QueryModel } from '../models/query-model'

export default {
  name: 'NextPreviousButtons',
  props: {
    value: {
      type: QueryModel,
      required: false
    }
  }
}
</script>

<style scoped>

</style>
