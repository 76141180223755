import { destroyToken } from '../authentication'
import Cookies from 'js-cookies'
import Requests from './Requests'

export default {
  mixins: [Requests],
  methods: {
    logoutUser () {
      this.getPromise(this.$store.state.endpoints.logout)
      destroyToken()
      Cookies.removeItem('csrftoken')
      localStorage.clear()
    },
    setUserLanguage (lang) {
      if (this.$store.getters.isAuthenticated) {
        this.patchPromise(
          this.$store.state.endpoints.userInfo,
          {
            lang: lang.toUpperCase()
          }
        )
      }
    },
    setLanguage (lang) {
      // eslint-disable-next-line no-console
      // console.log(lang);
      this.$store.commit('setLanguage', lang)
      this.$vuetify.rtl = (lang === 'ar')
      this.setUserLanguage(lang)
    }
  },
  computed: {
    loggedIn () {
      return this.$store.getters.isAuthenticated
    },
    rtlLang () {
      return this.$store.getters.lang && this.$store.lang === 'ar'
    },
    userName () {
      if (this.$store.getters.userInfo) {
        if (this.$store.getters.userInfo.firstName) {
          return this.$store.getters.userInfo.firstName
        }
        return this.$store.getters.userInfo.username
      }
      return ''
    }
    // authenticated() {
    //     return this.loggedIn;
    // }
  }
}
