import axios from 'axios'
import { joinUrls } from '../funclib'
import Cookies from 'js-cookies'

axios.defaults.withCredentials = false

// eslint-disable-next-line no-unused-vars
function onSuccessFunc (response) {
  // do nothing
}

function onErrorFunc (error) {
  // eslint-disable-next-line no-console
  console.log(error)
}

export default {
  methods: {
    getHeaders () {
      const headers = {}

      if (this.$store.getters.jwt) { headers.Authorization = 'Bearer ' + this.$store.getters.jwt }

      if (Cookies.getItem('csrftoken')) {
        headers['X-CSRFToken'] = Cookies.getItem('csrftoken')
      }

      // eslint-disable-next-line no-console
      // console.log(headers)
      return headers
    },
    axiosGet (url, onSuccess, onError) {
      if (!onSuccess) { onSuccess = onSuccessFunc }
      if (!onError) {
        onError = onErrorFunc
      }

      axios.get(
        joinUrls(this.$store.state.apiBaseUrl, url),
        {
          headers: this.getHeaders()
        }
      ).then(
        (response) => { onSuccess(response) }
      ).catch(
        (error) => { onError(error) }
      )
    },
    getPromise (url) {
      return axios.get(
        url,
        {
          headers: this.getHeaders()
        }
      )
    },
    axiosPatch (url, data, onSuccess, onError) {
      if (!onSuccess) { onSuccess = onSuccessFunc }
      if (!onError) {
        onError = onErrorFunc
      }

      axios.patch(
        joinUrls(this.$store.state.apiBaseUrl, url),
        data,
        {
          headers: this.getHeaders()
        }
      ).then(
        (response) => {
          // eslint-disable-next-line no-console
          // console.log('axiosPatch: success');
          onSuccess(response)
        }
      ).catch(
        (error) => { onError(error) }
      )
    },
    patchPromise (url, payload) {
      return axios.patch(
        url,
        payload,
        {
          headers: this.getHeaders()
        }
      )
    },
    axiosPut (url, data, onSuccess, onError) {
      if (!onSuccess) { onSuccess = onSuccessFunc }
      if (!onError) {
        onError = onErrorFunc
      }

      axios.put(
        joinUrls(this.$store.state.apiBaseUrl, url),
        data,
        {
          headers: this.getHeaders()
        }
      ).then(
        (response) => { onSuccess(response) }
      ).catch(
        (error) => { onError(error) }
      )
    },
    axiosPost (url, data, onSuccess, onError) {
      if (!onSuccess) { onSuccess = onSuccessFunc }
      if (!onError) {
        onError = onErrorFunc
      }

      axios.post(
        joinUrls(this.$store.state.apiBaseUrl, url),
        data,
        {
          headers: this.getHeaders()
        }
      ).then(
        (response) => { onSuccess(response) }
      ).catch(
        (error) => { onError(error) }
      )
    }
  },
  computed: {
    getApiBaseUrl () {
      return this.$store.state.apiBaseUrl
    }
  }
}
