import { BaseListModel, BaseModel } from '../lib/model'

export class StrongsModel extends BaseModel {
  strongsNum;
  word;
  lang;
  partOfSpeech;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}

export class StrongsListModel extends BaseListModel {
  _class = StrongsModel;

  constructor (list) {
    super()
    this.modelCopy(list)
  }

  strongsNum () {
    let text = ''
    this.list.forEach(strongs => {
      text += (text ? ' ' : '') + strongs.strongsNum
    })
    return text
  }
}
