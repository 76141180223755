export function defined (val) {
  return typeof val !== 'undefined' && val !== null
}

const hindiNums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

export function arabic2hindi (num) {
  return String(num).replace(/([0-9])/g, function (n) { return hindiNums[n] })
}

const arabicNums = { '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4', '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9' }

export function hindi2arabic (num) {
  return String(num).replace(/([٠-٩])/g, function (n) { return arabicNums[n] })
}

export const parsingPropertiesList = [
  'root',
  'partOfSpeech',
  'gender',
  'person',
  'number',
  'case',
  'state',
  'tense',
  'voice',
  'mood',
  'aspect',
  'stem',
  'pentateuch',
  'historical',
  'poetical',
  'prophetical'
]
