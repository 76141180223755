import { BaseListModel, BaseModel } from '@/lib/model'

export class StrongsWordModel extends BaseModel {
  strongsWord;
  seqNum;
  lang;
  transliteration;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}

export class StrongsWordListModel extends BaseListModel {
  _class = StrongsWordModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
