import { BaseListModel, BaseModel } from '../lib/model'
import { BiblePassage } from './bible-reference'

export const FILTER_PASSAGE = 'passage'
export const FILTER_STRONGS = 'strongs'
export const FILTER_WORKS = 'works'
export const FILTER_BOOKS = 'books'
export const FILTER_MORPHOLOGICAL = 'morphological'
export const FILTER_ROOT = 'root'
export const FILTER_START_FROM = 'start_from'

export const SECTION_PENTATEUCH = 'pentateuch'
export const SECTION_HISTORICAL = 'historical'
export const SECTION_POETICAL = 'poetical'
export const SECTION_PROPHETS = 'prophets'
export const SECTION_MINOR_PROPHETS = 'minorProphets'
export const SECTION_MAJOR_PROPHETS = 'majorProphets'
export const SECTION_LUKAN = 'lukan'
export const SECTION_GOSPELS = 'gospels'
export const SECTION_PAULINE = 'pauline'
export const SECTION_JOHANNINE = 'johannine'
export const SECTION_GENERAL_EPISTLES = 'generalEpistles'
export const SECTIONS = [
  SECTION_PENTATEUCH,
  SECTION_HISTORICAL,
  SECTION_POETICAL,
  SECTION_PROPHETS,
  SECTION_MINOR_PROPHETS,
  SECTION_MAJOR_PROPHETS,
  SECTION_GOSPELS,
  SECTION_LUKAN,
  SECTION_PAULINE,
  SECTION_JOHANNINE,
  SECTION_GENERAL_EPISTLES
]

class QueryFilter extends BaseModel {
  name;
  title;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isValid () {
    return !!this.name
  }

  serialize () {
    return {
      name: this.name
    }
  }
}

export class QueryFilterMorphological extends QueryFilter {
  name = FILTER_MORPHOLOGICAL;
  title = 'Morphological';
  partOfSpeech;
  person;
  gender;
  number;
  tense;
  voice;
  mood;
  state;
  aspect;
  stem;
  root;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  serialize () {
    const encoded = super.serialize()

    if (this.person) {
      encoded.person = this.person
    }
    if (this.gender) {
      encoded.gender = this.gender
    }
    if (this.number) {
      encoded.number = this.number
    }
    if (this.aspect) {
      encoded.aspect = this.aspect
    }
    if (this.stem) {
      encoded.stem = this.stem
    }
    if (this.root) {
      encoded.root = this.root
    }
    return encoded
  }

  empty () {
    return !this.person && !this.gender && !this.number && !this.aspect && !this.stem && !this.root
  }

  isValid () {
    return super.isValid() && !this.empty()
  }
}

export class QueryFilterStrongs extends QueryFilter {
  name = FILTER_STRONGS;
  title = "Strongs' Number";
  strongs = [];

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isValid () {
    return super.isValid() && !!this.strongsNum
  }

  serialize () {
    const filter = super.serialize()
    filter.strongs = this.strongs
    return filter
  }
}

export class QueryFilterStartFrom extends QueryFilter {
  name = FILTER_START_FROM;
  title = 'Start From';
  verseId;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isValid () {
    return super.isValid() && !!this.verseId
  }
}

export class QueryFilterBooks extends QueryFilter {
  name = FILTER_BOOKS;
  title = 'Book(s)';
  sections = [];
  books = [];

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  pushSection (section) {
    this.sections.push(section)
  }

  pushBook (bookId) {
    this.books.push(bookId)
  }

  empty () {
    return this.sections.length === 0 && this.books.length === 0
  }

  isValid () {
    return super.isValid() && !this.empty()
  }

  serialize () {
    const data = super.serialize()
    if (this.sections.length) {
      data.sections = this.sections
    }
    if (this.books.length) {
      data.books = this.books
    }
    return data
  }
}

export class QueryFilterWorks extends QueryFilter {
  name = FILTER_WORKS;
  title = 'Work(s)';
  works = [];

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isValid () {
    return super.isValid() && this.works.length()
  }
}

export class QueryFilterPassage extends QueryFilter {
  name = FILTER_PASSAGE;
  title = 'Passage(s)';
  unparsed;
  passage;
  human;

  constructor (data) {
    super()
    this.modelCopy(data)

    if (!(this.passage instanceof BiblePassage)) {
      this.passage = new BiblePassage(this.passage)
    }
  }

  setUnparsed (val) {
    this.unparsed = val
    this.value = null
  }

  setEncoded (val) {
    this.encoded = val
  }

  isValid () {
    return super.isValid() && !!this.encoded
  }

  serialize () {
    const encoded = super.serialize()
    encoded.encoded = this.encoded
    if (this.passage instanceof BiblePassage) {
      encoded.passage = this.passage.serialize()
    }
    return encoded
  }
}

export class QueryFilterRoot extends QueryFilter {
  name = FILTER_ROOT;
  title = 'Root';
  root;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  isValid () {
    return super.isValid() && !!this.root
  }

  serialize () {
    const encoded = super.serialize()
    encoded.root = this.root
    return encoded
  }
}

export class QueryFilterList extends BaseListModel {
  constructor (obj) {
    super()
    // console.log('QueryFilterList::constructor', list)
    this.modelCopy()
    if (obj) {
      for (const attr in obj) {
        this.push(obj[attr])
      }
    }
  }

  push (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item instanceof Object && item.hasOwnProperty('name')) {
      super.push(this.factory(item))
    }
  }

  length () {
    return this.list.length
  }

  index (i) {
    return this.list[i]
  }

  get (name) {
    const index = this.getIndex(name)
    if (index !== null) {
      return this.list[index]
    }
    return null
  }

  getIndex (name) {
    for (let i = 0; i < this.list.length; i++) {
      if (this.list[i].name === name) {
        return i
      }
    }
    return null
  }

  remove (name) {
    const i = this.getIndex(name)
    if (typeof i !== 'undefined') {
      this.list.splice(i, 1)
      delete this[name]
    }
  }

  removeAll () {
    for (let i = 0; i < this.list.length; i++) {
      delete this[this.list[i].name]
    }
    this.list = []
  }

  has (name) {
    return typeof this.getIndex(name) !== 'undefined'
  }

  getData () {
    const json = []
    for (let i = 0; i < this.list.length; i++) {
      json.push(this.list[i].api.getData())
    }

    return json
  }

  factory (data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data instanceof Object && data.hasOwnProperty('name')) {
      switch (data.name) {
        case FILTER_STRONGS:
          return new QueryFilterStrongs(data)
        case FILTER_PASSAGE:
          return new QueryFilterPassage(data)
        case FILTER_WORKS:
          return new QueryFilterWorks(data)
        case FILTER_BOOKS:
          return new QueryFilterBooks(data)
        case FILTER_START_FROM:
          return new QueryFilterStartFrom(data)
        case FILTER_ROOT:
          return new QueryFilterRoot(data)
        case FILTER_MORPHOLOGICAL:
          return new QueryFilterMorphological(data)
      }
    }

    return null
  }
}
