import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
// import Notifications from 'vue-notification'

Vue.config.productionTip = false
// Vue.use(Notifications)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
