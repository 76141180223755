import { BaseListModel, BaseModel } from '../lib/model'
import { ParsingModel } from './parsing-model'
import { StrongsListModel } from './strongs-model'
import { ScriptHebrew } from '../lib/funclib'
export class WordModel extends BaseModel {
  root;
  rootId;
  rootLang;
  lang;
  strongs;
  wordId;
  word;
  htmlWord;
  seqNum
  partNum;
  workRef;
  puncBefore;
  puncAfter;
  parsing;
  highlighted = false;
  _spaceBefore = true;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  hasSpaceBefore () {
    return this._spaceBefore
  }

  hasMaqef () {
    return this.puncAfter === '־'
  }

  hasParsing () {
    return this.parsing instanceof Object
  }

  modelCopy (data) {
    if (data instanceof Object) {
      super.modelCopy(data)
      this.htmlWord = this.word
      if (this.puncAfter && this.puncAfter.length) {
        this.puncAfter = this.puncAfter.trim()
      }
      if (this.strongs instanceof Array) {
        this.strongs = new StrongsListModel(this.strongs)
      }
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('parsing')) {
        this.parsing = new ParsingModel(data.parsing)
      }
    }
  }
}

export class WordListModel extends BaseListModel {
  _class = WordModel;
  _processed = false;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  processWords () {
    if (this._processed) {
      return
    }
    for (let i = 0; i < this.list.length; i++) {
      if (i > 0) {
        if (this.list[i].partNum === 1 && this.list[i - 1].hasMaqef()) {
          this.list[i]._spaceBefore = false
        } else if (this.list[i].partNum > 1) {
          this.list[i]._spaceBefore = false
        }
      }
    }
    this._processed = true
  }

  wholeWords () {
    this.processWords()
    let seq = []
    for (const w of this.list) {
      if (w.seqNum > seq.length) {
        seq[w.seqNum - 1] = [w]
      } else {
        seq[w.seqNum - 1].push(w)
      }
    }
    seq = seq.filter(elem => typeof elem !== 'undefined')
    for (const w of seq) {
      for (let i = 1; i < w.length; i++) {
        while (ScriptHebrew.startsWithVowel(w[i].htmlWord)) {
          w[i - 1].htmlWord += w[i].htmlWord[0]
          w[i].htmlWord = w[i].htmlWord.slice(1)
        }
      }
    }
    return seq
  }
}
