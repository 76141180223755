<template>
  <v-container>
    <v-form :class="selectClass + 'hebrew-search transparent'" :lang="$i18n.locale">
      <h2>Hebrew Morphological Search</h2>
      <v-row>
        <v-col sm>
          <v-text-field
            id="root"
            :label="$t('field.hebrewRoot')"
            v-model="root"
            v-on:keyup="trapEnter"
            lang="he"
          />
          <div class="button-group">
            <v-btn
              :color="buttonPrimaryColor"
              :lang="$i18n.locale"
              @click="search()"
            >
              {{ $t('nav.search') }}
            </v-btn>
          </div>
        </v-col>
        <v-col sm>
          <v-select
            :items="stems()"
            :class="selectClass"
            :label="$t('stemHebrew.name')"
            v-model="stem"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
          <v-select
            :class="selectClass"
            :items="aspects()"
            :label="$t('aspect.name')"
            v-model="aspect"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
        </v-col>
        <v-col sm>
          <v-select
            :class="selectClass"
            :items="persons()"
            :label="$t('person.name')"
            v-model="person"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
          <v-select
            :class="selectClass"
            :items="genders()"
            :label="$t('gender.name')"
            v-model="gender"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
        </v-col>
        <v-col sm>
          <v-select
            :class="selectClass"
            :items="numbers()"
            :label="$t('number.name')"
            v-model="number"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm>
          <v-select
            :class="selectClass"
            :items="sections()"
            :label="$t('field.bibleSections')"
            v-model="bookSections"
            :lang="$i18n.locale"
            :clearable="true"
            chips
            multiple
          ></v-select>
        </v-col>
      </v-row>
    </v-form>
    <polyglot v-model="query.polyglot" :verse-headers="true">
      <template #heading>
        <h2>{{ resultsHeading() }}</h2>
      </template>
      <template #input>
        <p></p>
      </template>
    </polyglot>
    <next-previous-buttons
      v-model="query"
      @next="getNext()"
      @previous="getPrevious()"
    ></next-previous-buttons>
  </v-container>
</template>

<script>
import Colors from '../lib/mixins/Colors'
import {
  QueryFilterBooks,
  QueryFilterMorphological,
  SECTION_HISTORICAL, SECTION_MAJOR_PROPHETS, SECTION_MINOR_PROPHETS,
  SECTION_PENTATEUCH,
  SECTION_POETICAL,
  SECTION_PROPHETS
} from '../classes/filters'
import { QueryModel } from '../models/query-model'
import Polyglot from '../components/Polyglot'
import Notifications from '../lib/mixins/Notifications'
import NextPreviousButtons from '../components/NextPreviousButtons'
import SearchMixin from '../mixins/SearchMixin'

export default {
  name: 'HebrewSearch',
  components: { NextPreviousButtons, Polyglot },
  mixins: [Notifications, Colors, SearchMixin],
  data () {
    return {
      query: new QueryModel(),
      root: null,
      stem: null,
      aspect: null,
      gender: null,
      person: null,
      number: null,
      bookSections: []
    }
  },
  methods: {
    stems () {
      return [
        { text: this.$t('stemHebrew.qal'), value: 'STEM_QAL' },
        { text: this.$t('stemHebrew.qalPassive'), value: 'STEM_QAL_PASSIVE' },
        { text: this.$t('stemHebrew.nifal'), value: 'STEM_NIFAL' },
        { text: this.$t('stemHebrew.piel'), value: 'STEM_PIEL' },
        { text: this.$t('stemHebrew.pual'), value: 'STEM_PUAL' },
        { text: this.$t('stemHebrew.hifil'), value: 'STEM_HIFIL' },
        { text: this.$t('stemHebrew.hofal'), value: 'STEM_HOFAL' },
        { text: this.$t('stemHebrew.hithpael'), value: 'STEM_HITHPAEL' }
      ]
    },
    aspects () {
      return [
        { text: this.$t('aspect.perfect'), value: 'ASPECT_PERFECT' },
        { text: this.$t('aspect.imperfect'), value: 'ASPECT_IMPERFECT' },
        { text: this.$t('aspect.imperative'), value: 'ASPECT_IMPERATIVE' },
        { text: this.$t('aspect.participle'), value: 'ASPECT_PARTICIPLE' },
        { text: this.$t('aspect.infinitive'), value: 'ASPECT_INFINITIVE' }
      ]
    },
    genders () {
      return [
        { text: this.$t('gender.masculine'), value: 'GENDER_MALE' },
        { text: this.$t('gender.feminine'), value: 'GENDER_FEMALE' },
        { text: this.$t('gender.common'), value: 'GENDER_COMMON' }
      ]
    },
    persons () {
      return [
        { text: this.$t('person.first'), value: 'PERSON_FIRST' },
        { text: this.$t('person.second'), value: 'PERSON_SECOND' },
        { text: this.$t('person.third'), value: 'PERSON_THIRD' }
      ]
    },
    numbers () {
      return [
        { text: this.$t('number.singular'), value: 'NUMBER_SINGULAR' },
        { text: this.$t('number.dual'), value: 'NUMBER_DUAL' },
        { text: this.$t('number.plural'), value: 'NUMBER_PLURAL' }
      ]
    },
    sections () {
      return [
        { text: this.$t('bibles.sections.pentateuch'), value: SECTION_PENTATEUCH },
        { text: this.$t('bibles.sections.historical'), value: SECTION_HISTORICAL },
        { text: this.$t('bibles.sections.poetical'), value: SECTION_POETICAL },
        { text: this.$t('bibles.sections.prophetic'), value: SECTION_PROPHETS },
        { text: this.$t('bibles.sections.majorProphets'), value: SECTION_MAJOR_PROPHETS },
        { text: this.$t('bibles.sections.minorProphets'), value: SECTION_MINOR_PROPHETS }
      ]
    },
    search () {
      this.query.filters.removeAll()
      const morphFilter = new QueryFilterMorphological({
        aspect: this.aspect,
        stem: this.stem,
        gender: this.gender,
        person: this.person,
        number: this.number,
        root: this.root
      })
      if (morphFilter.isValid()) {
        this.query.filters.push(morphFilter)
      }
      const booksFilter = new QueryFilterBooks({
        sections: this.bookSections
      })
      if (booksFilter.isValid()) {
        this.query.filters.push(booksFilter)
      }
      if (this.query.filters.length()) {
        this.queryFilters()
      } else {
        // console.log('bad reference!')
        this.notifyError(this.$t('notify.badReference'))
      }
    }
  },
  computed: {
    selectClass () {
      if (this.$vuetify.rtl) {
        return 'ui rtl'
      }
      return 'ui ltr'
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .hebrew-search {
    width: 400px;
    direction: ltr;
    padding: 10px;
  }

  .rtl {
    font-size: 150%;
    direction: rtl;
  }

  .ltr {
    direction: ltr;
  }

  .transparent {
    background-color: rgba(255,255,255,0.6) !important;
    border-radius: 5px;
  }
</style>
