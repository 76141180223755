<template>
  <v-container>
    <span
      v-if="textIndex === 0 || textIndex === 3"
      class="verse-number"
    >{{ verseNum(row.reference, text) }} </span>
    <span
      v-else-if="row.reference.verse() !== text.reference.verse()"
      class="verse-number"
    >{{ verseNum(text.reference, text) }} </span>
    <span
      v-for="(parts, partsIndex) in text.words.wholeWords()"
      :key="partsIndex"
      @click="$emit('parse', parts, text, $event)"
    ><span
      v-for="(part, partIndex) in parts"
      :key="partIndex"
    ><span
      v-if="part.hasSpaceBefore()"
    >{{ ' ' }}</span>{{ part.puncBefore }}<span
      :class="wordClass(parts, partIndex)"
    >{{ part.htmlWord }}</span><span v-if="part.puncAfter">{{ part.puncAfter }}</span></span></span>
  </v-container>
</template>

<script>
import { QueryRowModel } from '../models/query-row-model'
import { QueryTextRowModel } from '../models/query-text-row-model'
import ParsingMixin from '../mixins/ParsingMixin'

export default {
  name: 'TextRow',
  mixins: [ParsingMixin],
  props: {
    textIndex: {
      type: Number,
      required: true
    },
    row: {
      type: QueryRowModel,
      required: true
    },
    text: {
      type: QueryTextRowModel,
      required: true
    }
  },
  methods: {
    wordClass (parts, partsIndex) {
      let classes = ''
      if (this.partsHaveParsing(parts, partsIndex)) {
        classes += 'word-with-parsing'
      }
      // console.log(partsIndex, parts)
      if (parts[partsIndex].highlighted) {
        classes += ' highlighted'
      }
      return classes
    },
    partClass (part) {
      return part.highlighted ? 'highlight' : ''
    }
  }
}
</script>

<style scoped>
  .verse-number {
    font-weight: bold;
  }

  .word-with-parsing:hover {
    background-color: yellow;
  }

  .highlighted {
    background-color: greenyellow;
  }
</style>
