import axios from 'axios'
// eslint-disable-next-line camelcase
import { get_default_headers } from './requests'
import { joinUrls } from './funclib'
import store from '@/store'
// import {Card} from "./models/Card";

export function apiClient () {
  return axios.create({
    baseURL: store.state.apiBaseUrl,
    withCredentials: false, // This is the default
    headers: get_default_headers(),
    timeout: 10000
  })
}

export const STATUS_NEW = 'N'
export const STATUS_CHANGED = 'C'
export const STATUS_DELETED = 'D'
export const STATUS_ARCHIVED = 'A'

class ApiBase {
    parent;
    getLoaded = false;
    fetched = false;
    fetching = false;
    url;
    pkAttribute = 'id';
    pkRegExp = new RegExp(':pk');
    modelFields = [];
    dateFields = [];
    updateFields = [];
    ignoreFields = [];
    status;

    constructor (parent) {
      this.parent = parent
    }

    getUrl (pk, url) {
      if (!url) {
        url = this.url
      }
      if (url.search(this.pkRegExp)) {
        url = url.replace(this.pkRegExp, pk)
        url = url.replace(/\/\/$/, '/')
        return url
      }
      return joinUrls(url, pk)
    }

    getModelFields () {
      if (!this.modelFields.length) {
        this.modelFields = []
        for (const attr in this.parent) {
          // console.log('Attr:', attr);
          if (attr !== 'api' && this.ignoreFields.indexOf(attr) === -1 && attr.substring(0, 1) !== '_') { this.modelFields.push(attr) }
        }
      }
      return this.modelFields
    }

    getData () {
      const data = {}
      for (const field of this.getModelFields()) {
        data[field] = this.parent[field]
      }
      return data
    }

    getPayload (fields) {
      const payload = {}
      if (!fields) {
        fields = this.updateFields
      }
      for (const field of fields) {
        const dbField = field.replace(/([A-Z])/g, function ($1) { return '_' + $1.toLowerCase() })
        // eslint-disable-next-line no-console
        // console.log(field, db_field);
        // eslint-disable-next-line no-prototype-builtins
        if (this.parent[field] instanceof Object && this.parent[field].hasOwnProperty('id')) {
          payload[dbField] = this.parent[field].id
        } else {
          payload[dbField] = this.parent[field]
        }
      }
      // eslint-disable-next-line no-console
      // console.log(payload);
      return payload
    }

    fetch (pk, url) {
      return this.fetchUrl(this.getUrl(pk, url))
    }

    fetchUrl (url) {
      this.getLoaded = false
      this.fetched = false
      // let that = this;
      this.fetching = true
      return apiClient().get(url).then(response => {
        this.parent.modelCopy(response.data)
        this.getLoaded = true
        this.fetched = true
        this.fetching = false
        return response
      }).catch(error => {
        this.fetching = false
        return error
      })
    }

    postUrl (url, payload) {
      this.getLoaded = false
      this.fetched = false
      // let that = this;
      this.fetching = true
      return apiClient().post(url, payload).then(response => {
        this.parent.modelCopy(response.data)
        this.getLoaded = true
        this.fetched = true
        this.fetching = false
        return response
      }).catch(error => {
        this.fetching = false
        return error
      })
    }

    save (fields) {
      return apiClient().patch(this.getUrl(this.parent[this.pkAttribute]), this.getPayload(fields))
    }

    create () {
      const that = this
      return apiClient().post(
        this.getUrl(''),
        this.getPayload()
      ).then(response => {
        // The Django rest framework returns a copy of the new object.
        // Load that into the model object
        that.parent.modelCopy(response.data)
      })
    }

    delete () {
      const that = this
      return apiClient().delete(this.getUrl(this.parent[this.pkAttribute])).then((response) => {
        that.status = STATUS_DELETED
        return response
      })
    }
}

export class ApiDetail extends ApiBase {
}

export class ApiList extends ApiBase {
  getUrl (pk, url) {
    if (!url) {
      url = this.url
    }
    if (pk) {
      return url.replace(this.pkRegExp, pk)
    }
    return url
  }
}

export class BaseModel {
    api = new ApiDetail(this);

    constructor (data, status) {
      if (data) {
        this.modelCopy(data)
      }
      if (status) {
        this.api.status = status
      }
    }

    instantiateModel (klass, property) {
      if (this[property] instanceof Object) {
        // eslint-disable-next-line new-cap
        this[property] = new klass(this[property])
      }
    }

    instantiateModelList (klass, property) {
      if (this[property] instanceof Array) {
        const list = this[property]
        this[property] = []
        for (const item of list) {
          // eslint-disable-next-line new-cap
          this[property].push(new klass(item))
        }
      }
    }

    modelCopy (data) {
      if (data instanceof Object) {
        const fieldNames = this.api.getModelFields()
        for (const attr in data) {
          if (attr !== 'api' && attr.substring(0, 1) !== '_') {
            const ccAttr = attr.replace(
              /(_\w)/g,
              function (match) { return match[1].toUpperCase() }
            )
            if (fieldNames.indexOf(ccAttr) >= 0) {
              this[ccAttr] = data[attr]
            } else {
              // eslint-disable-next-line no-console
              console.log(this.constructor.name, 'class has no attribute:', ccAttr, 'value:', data[ccAttr])
            }
          }
        }
        for (const key of this.api.dateFields) {
          if (!(this[key] instanceof Date)) { this[key] = new Date(this[key]) }
        }
      }
    }

    findId (id) {
      return this.list.find(item => item.id === id)
    }

    deleteId (id) {
      const index = this.findId(id)
      if (index !== -1) {
        this.list = this.list.splice(index, 1)
        return true
      }
      return false
    }
}

export class BaseChangeStampModel extends BaseModel {
    createdBy;
    createdDate;
    modifiedBy;
    modifiedDate;
}
export class BaseListModel extends BaseModel {
    api = new ApiList(this);
    list = [];
    _class = null;

    modelCopy (items) {
      if (items instanceof Array) {
        this.list = []
        for (const item of items) {
          this.push(item)
        }
      }
    }

    // Deprecated
    pushItem (item) {
      this.push(item)
    }

    serialize () {
      const data = []
      for (const filter of this.list) {
        data.push(filter.serialize())
      }
      return data
    }

    push (item) {
      if (this._class) {
        item = new this._class(item)
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('api')) {
          item.api.fetched = true
        }
      }
      this.list.push(item)
      return item
    }

    get length () {
      return this.list.length
    }

    [Symbol.iterator] () {
      const items = Object.values(this.list)
      let currentIndex = 0

      return {
        // Implementation of next()
        next () {
          if (currentIndex >= items.length) {
            return {
              value: undefined,
              done: true
            }
          }

          return {
            value: items[currentIndex++],
            done: false
          }
        }
      }
    }
}

export class BaseGenericRelationModel extends BaseModel {
    contentType;
    objectId;
}
