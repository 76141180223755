import { render, staticRenderFns } from "./NotifyPopup.vue?vue&type=template&id=1485faec&scoped=true&"
import script from "./NotifyPopup.vue?vue&type=script&lang=js&"
export * from "./NotifyPopup.vue?vue&type=script&lang=js&"
import style0 from "./NotifyPopup.vue?vue&type=style&index=0&id=1485faec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1485faec",
  null
  
)

export default component.exports