<template>
    <tipped-text
      :tip="fullText"
    >{{ abbrevText }}</tipped-text>
</template>

<script>
import TippedText from './TippedText'
import ParsingMixin from '../mixins/ParsingMixin'
export default {
  name: 'ParsingAbbrev',
  components: { TippedText },
  mixins: [ParsingMixin],
  props: {
    parsing: {
      type: String,
      required: true
    }
  },
  computed: {
    fullText () {
      return this.$t(this.i18nTag(this.parsing))
    },
    abbrevText () {
      return this.$i18n.localize(this.$t(this.i18nAbbrevTag(this.parsing)))
    }
  }
}
</script>

<style scoped>

</style>
