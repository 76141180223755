import slugs2tags from '../biblical-data/slugs2i18n'
import { arabic2hindi } from '../funclib'

export default {
  methods: {
    i18nTag (slug) {
      return slugs2tags[slug]
    },
    i18nAbbrevTag (slug) {
      // console.log('slug:', slug)
      const tags = slugs2tags[slug].split('.')
      return tags[0] + '.abbrev.' + tags[1]
    },
    i18WorkTag (workId) {
      return 'bibles.' + workId.replace(/[-.]/g, '_')
    },
    verseNum (reference, text) {
      let vn = reference.verse()
      if (vn === 1) {
        vn = reference.chapter() + ':' + (text.lang === 'ar' || text.lang === 'he' ? '\u200f' : '') + vn
      } else if (vn === 0) {
        vn = ''
      }
      if (text.lang === 'ar') {
        vn = arabic2hindi(vn)
      }
      return vn
    },
    partsHaveParsing (parts, partsIndex) {
      if (parts) {
        for (const part of parts) {
          if (part.parsing || part.strongs || part.root) {
            return true
          }
        }
      } else {
        console.log('parts undefined at index:', partsIndex)
      }
      return false
    }
  }
}
