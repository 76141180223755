<template>
  <v-container>
    <polyglot-snack v-model="query.polyglot" :verse-headers="true">
      <template #input>
        <v-row>
          <v-col sm="auto">
            <v-text-field
              id="root"
              :label="$t('field.strongsNum')"
              v-model="strongsNum"
              v-on:keyup="trapEnter"
              :lang="$i18n.locale"
              class="ui transparent lookup-field"
            />
          </v-col>
          <v-col sm="auto">
            <div class="button-group">
              <v-btn
                :color="buttonPrimaryColor"
                @click="search()"
                class="lookup-button"
              >
                {{ $t('action.go') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
      <template #heading>
        <h2>{{ resultsHeading() }}</h2>
      </template>
    </polyglot-snack>
    <next-previous-buttons
      v-model="query"
      @previous="getPrevious()"
      @next="getNext()"
    ></next-previous-buttons>
  </v-container>
</template>

<script>
import Colors from '../lib/mixins/Colors'
import { QueryModel } from '../models/query-model'
import Notifications from '../lib/mixins/Notifications'
import { QueryFilterStrongs } from '../classes/filters'
import NextPreviousButtons from '../components/NextPreviousButtons'
import SearchMixin from '../mixins/SearchMixin'
import PolyglotSnack from '../components/PolyglotSnack'

export default {
  name: 'StrongsSearch',
  components: { PolyglotSnack, NextPreviousButtons },
  mixins: [Notifications, Colors, SearchMixin],
  data () {
    return {
      query: new QueryModel(),
      strongsNum: null
    }
  },
  methods: {
    search () {
      if (this.strongsNum) {
        this.query.filters.removeAll()
        this.query.filters.push(new QueryFilterStrongs({ strongs: [this.strongsNum] }))
        this.queryFilters()
      } else {
        // console.log('bad reference!')
        this.notifyError(this.$t('notify.badReference'))
      }
    }
  }
}
</script>

<style scoped>
.lookup-field {
  padding: 20px 10px 0;
}

.lookup-button {
  margin-top: 20px;
}
</style>
