import { ApiDetail, BaseModel } from '../lib/model'
import { QueryPassageResults } from './query-passage-results'
import { FILTER_PASSAGE, QueryFilterList, QueryFilterPassage } from '../classes/filters'
import { QueryStrongsResults } from './query-strongs-results'
import { BiblePassage } from '../classes/bible-reference'
import { QuerySessionModel } from './query-session'

export class QueryModel extends BaseModel {
  status;
  success;
  type;
  compressed;
  format = 'json';
  initial;
  filters = new QueryFilterList();
  session;

  polyglot;
  morphological;
  strongs;
  passage;

  api = new class extends ApiDetail {
    url = '/query-json.php'
    queryPassage (passage) {
      const json = {
        action: FILTER_PASSAGE,
        lang: 'ar',
        initial: true,
        params: {
          passage: passage.serialize()
        }
      }
      const i = this.parent.filters.getIndex(FILTER_PASSAGE)
      console.log('filters:', this.parent.filters)
      console.log('passage index:', i)
      if (i === null) {
        this.parent.filters.push(
          new QueryFilterPassage({ passage: passage })
        )
      } else {
        this.parent.filters.list[i].passage = passage
      }
      if (this.parent.filters && this.parent.filters.length) {
        json.params.filters = this.parent.filters.serialize()
      }
      return this.postUrl(this.url, json)
    }

    queryStrongs (strongsNum, workId) {
      const json = {
        action: 'strongs',
        lang: 'ar',
        initial: true,
        params: {
          strongs_num: strongsNum,
          work_id: workId
        }
      }
      return this.postUrl(this.url, json)
    }

    queryFilters (action) {
      const json = {
        action: action || 'search',
        lang: 'ar',
        session: this.parent.session ? this.parent.session.serialize() : (new QuerySessionModel()).serialize(),
        params: {
          filters: this.parent.filters.serialize()
        }
      }
      return this.postUrl(this.url, json)
    }
  }(this);

  constructor (data) {
    super()
    this.filters = new QueryFilterList()
    this.modelCopy(data)
  }

  modelCopy (data) {
    super.modelCopy(data)
    if (data) {
      // console.log('filters:', data.filters)
      if (data.session) {
        this.session = new QuerySessionModel(this.session)
      } else {
        this.session = new QuerySessionModel()
      }
      if (data.passage) {
        this.passage = new BiblePassage(data.passage)
      }
      if (data.filters) {
        this.filters = new QueryFilterList(data.filters)
      }

      if (data.polyglot) {
        this.polyglot = new QueryPassageResults(data.polyglot)
      }

      if (data.strongs) {
        this.strongs = new QueryStrongsResults(data.strongs)
      }
    }
  }

  clear () {
    this.passage = null
    this.polyglot = null
    this.strongs = null
    this.filters = null
  }

  addFilter (filter) {
    this.filters.push(filter)
  }
}
