import axios from 'axios'
import jwtDecode from 'jwt-decode'
import store from '../store'
import router from '../router'
// eslint-disable-next-line camelcase
import { get_default_headers } from './requests'
import Cookies from 'js-cookies'

export function tokenUserInfo (token) {
  if (token) {
    const decoded = jwtDecode(token)
    // eslint-disable-next-line no-console
    // console.log(decoded);
    return {
      email: decoded.email,
      id: decoded.user_id,
      username: decoded.username,
      expiration: decoded.exp,
      origIat: decoded.orig_iat
    }
  }
  return null
}

export function requestToken (username, password, onSuccess, onError) {
  const payload = {
    username: username,
    password: password
  }
  axios.post(
    store.state.endpoints.obtainJWT,
    payload,
    {
      headers: get_default_headers()
    }
  ).then((response) => {
    store.commit('updateToken', response.data.token)
    if (onSuccess) { onSuccess(response) }
  }).catch((error) => {
    if (onError) { onError(error) }
  })
}

export function refreshToken (onSuccess, onError) {
  const payload = {
    token: store.getters.jwt
  }
  axios.post(store.state.endpoints.refreshJWT,
    payload,
    {
      headers: get_default_headers()
    }
  )
    .then((response) => {
      store.commit('updateToken', response.data.token)
      if (onSuccess) {
        onSuccess(response)
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
      if (onError) {
        onError(error)
      }
    })
}

export function destroyToken () {
  store.commit('removeToken')
}

export function inspectToken () {
  const token = store.getters.jwt
  if (token) {
    const decoded = jwtDecode(token)
    const exp = decoded.exp
    const origIat = decoded.orig_iat
    if (exp - (Date.now() / 1000) < 1800 && (Date.now() / 1000) - origIat < 628200) {
      refreshToken()
    } else if (exp - (Date.now() / 1000) < 1800) {
      // DO NOTHING, DO NOT REFRESH
    } else {
      // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
      destroyToken()
    }
  }
}

// eslint-disable-next-line no-unused-vars
export function getUserInfo (userId, onSuccess, onError) {
  if (store.getters.isAuthenticated) {
    const payload = {
    }
    axios.get(
      store.state.endpoints.userInfo,
      {
        headers: get_default_headers()
      },
      payload
    )
      .then((response) => {
        store.commit('updateUserInfo', {
          username: response.data.username,
          email: response.data.email,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          editCards: response.data.edit_cards,
          editStacks: response.data.edit_stacks,
          editCardsets: response.data.edit_cardsets
        })
        store.commit('setLanguage', response.data.lang.toLowerCase())
        if (onSuccess) {
          onSuccess(response)
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error)
        } else {
          // eslint-disable-next-line no-console
          console.log('Failed to load user information')
          if (error.response.data.code === 'token_not_valid') {
            // eslint-disable-next-line no-console
            console.log('Token invalid')
            // that.$router.push({ name: 'logout' });
            destroyToken()
            Cookies.removeItem('csrftoken')
            localStorage.clear()
            router.push({ name: 'logout' })
          }
        }
      })
  }
}
