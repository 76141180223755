export default {
  methods: {
    notifySuccess (message) {
      console.log('notifySuccess:', message)
      this.$store.dispatch('notifySuccess', message)
    },
    notifyError (message) {
      console.log('notifyError:', message)
      this.$store.dispatch('notifyError', message)
    },
    notifyWarning (message) {
      console.log('notifyWarning:', message)
      this.$store.dispatch('notifyWarning', message)
    }
  }
}
