import { BaseListModel, BaseModel } from '@/lib/model'

export class StrongsMeaningModel extends BaseModel {
  meaning;
  seqNum;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}

export class StrongsMeaningListModel extends BaseListModel {
  _class = StrongsMeaningModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
