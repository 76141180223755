import { BaseListModel, BaseModel } from '../lib/model'

export class WorkHeadingModel extends BaseModel {
  bibleId;
  workId;
  title;
  shortTitle;
  lang;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}

export class WorkHeadingListModel extends BaseListModel {
  _class = WorkHeadingModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
