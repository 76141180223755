import { BaseListModel, BaseModel } from '../lib/model'

export class QuerySessionModel extends BaseModel {
  action;
  moreResults;
  occurrences;
  pages;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  modelCopy (data) {
    super.modelCopy(data)

    if (this.pages) {
      this.pages = new QueryPageListModel(this.pages)
    }
  }

  lastPage () {
    return this.pages.list[this.pages.length - 1]
  }

  offset () {
    if (this.pages.length) {
      return this.lastPage().offset
    }
  }

  hits () {
    if (this.pages.length) {
      return this.lastPage().hits
    }
  }

  serialize () {
    const data = {
      action: this.action,
      more_results: this.moreResults,
      occurrences: this.occurrences
    }
    if (this.pages) {
      data.pages = this.pages.serialize()
    }

    return data
  }
}

export class QueryPageModel extends BaseModel {
  offset;
  hits;
  hitsCount;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  serialize () {
    return {
      offset: this.offset,
      hits: this.hits,
      hits_count: this.hitsCount
    }
  }
}

export class QueryPageListModel extends BaseListModel {
  _class = QueryPageModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
