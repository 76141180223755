export default {
  // MOD_ACTIVE_IMPERSONAL: "modifier.activeImpersonal",
  // MOD_APOCOPATED_FORM: 'modifier.apocopatedForm',
  // MOD_ARAMAISM: "modifier.aramaism",
  // MOD_COHORTATIVE_FORM: "modifier.cohortativeForm",
  // MOD_COMPARATIVE: "modifier.comparative",
  // MOD_DEPONENT: "modifier.deponent",
  // MOD_HEBRAISM: "modifier.hebraism",
  // MOD_IMPERATIVE_SENSE: "modifier.imperativeSense",
  // MOD_IMPERSONAL: "modifier.impersonal",
  // MOD_INDECLINABLE_LETTER: "modifier.indeclinableLetter",
  // MOD_INDECLINABLE_OTHER: "modifier.indeclinableOther",
  // MOD_SUPERLATIVE: "modifier.superlative",
  APOCOPATED_FORM: 'modifier.apocopatedForm',
  ARAMAISM: 'modifier.aramaism',
  ASPECT_IMPERATIVE: 'aspect.imperative',
  ASPECT_IMPERFECT: 'aspect.imperfect',
  ASPECT_INFINITIVE: 'aspect.infinitive',
  ASPECT_PARTICIPLE: 'aspect.participle',
  ASPECT_PERFECT: 'aspect.perfect',
  CASE_ACCUSATIVE: 'case.accusative',
  CASE_DATIVE: 'case.dative',
  CASE_GENITIVE: 'case.genitive',
  CASE_NOMINATIVE: 'case.nominative',
  CASE_VOCATIVE: 'case.vocative',
  COMPARATIVE: 'modifier.comparative',
  DEPONENT: 'modifier.deponent',
  // GENDER_BOTH: 'gender.common',
  GENDER_COMMON: 'gender.common',
  GENDER_FEMININE: 'gender.feminine',
  GENDER_MASCULINE: 'gender.masculine',
  GENDER_NEUTER: 'gender.neuter',
  HEBRAISM: 'modifier.hebraism',
  INDECLINABLE_LETTER: 'modifier.indeclinableLetter',
  INDECLINABLE_OTHER: 'modifier.indeclinableOther',
  LANG_ARAMAIC: 'modifier.langAramaic',
  MARKER_INVERTED_NUN: 'marker.invertedNun',
  MARKER_PETUCHAH: 'marker.petuchah',
  MARKER_SETUMAH: 'marker.setumah',
  MOOD_IMPERATIVE: 'mood.imperative',
  MOOD_INDICATIVE: 'mood.indicative',
  MOOD_INFINITIVE: 'mood.infinitive',
  MOOD_OPTATIVE: 'mood.optative',
  MOOD_PARTICIPLE: 'mood.participle',
  MOOD_SUBJUNCTIVE: 'mood.subjunctive',
  NUMBER_DUAL: 'number.dual',
  NUMBER_PLURAL: 'number.plural',
  NUMBER_SINGULAR: 'number.singular',
  PERSON_FIRST: 'person.first',
  PERSON_SECOND: 'person.second',
  PERSON_THIRD: 'person.third',
  POS_ADJECTIVE: 'pos.adjective',
  POS_ADVERB: 'pos.adverb',
  POS_CONDITIONAL: 'pos.conditional',
  POS_CONJUNCTION: 'pos.conjunction',
  POS_COR_INT_PRONOUN: 'pos.pronounCorrelativeOrInterrogative',
  POS_COR_PRONOUN: 'pos.pronounCorrelative',
  POS_DEF_ARTICLE: 'pos.definiteArticle',
  POS_DEM_PRONOUN: 'pos.pronounDemonstrative',
  POS_DIRECT_OBJECT_MARKER: 'pos.directObjectMarker',
  POS_IND_PRONOUN: 'pos.pronounIndefinite',
  POS_INTERJECTION: 'pos.interjection',
  POS_INTERROGATIVE: 'pos.interrogative',
  POS_INTERROGATIVE_PRONOUN: 'pos.pronounInterrogative',
  POS_NEGATIVE_PARTICLE: 'pos.negativeParticle',
  POS_NOUN: 'pos.noun',
  POS_NOUN_GENTILIC: 'pos.nounGentilic',
  // POS_NUMBER_INDECLINABLE: "pos.numberIndeclinable",
  POS_PARTICLE: 'pos.particle',
  POS_PRONOUN: 'pos.pronoun',
  POS_PREPOSITION: 'pos.preposition',
  POS_PROPER_NOUN: 'pos.properNoun',
  POS_REC_PRONOUN: 'pos.pronounReciprocal',
  POS_REF_PRONOUN: 'pos.pronounReflexive',
  POS_REL_PRONOUN: 'pos.pronounRelative',
  // POS_SUBSTANTIVE: "pos.substantive",
  POS_VERB: 'pos.verb',
  STATE_ABSOLUTE: 'state.absolute',
  STATE_CONSTRUCT: 'state.construct',
  STATE_DETERMINED: 'state.determined',
  STEM_ARAMAIC_APH: 'stemAramaic.Aphel',
  STEM_ARAMAIC_HAPH: 'stemAramaic.Haphel',
  STEM_ARAMAIC_HEPH: 'stemAramaic.Hephel',
  STEM_ARAMAIC_HISH: 'stemAramaic.Hishtaphel',
  STEM_ARAMAIC_HOPH: 'stemAramaic.Hophal',
  STEM_ARAMAIC_HTAP: 'stemAramaic.Hithaphel',
  STEM_ARAMAIC_HTPA: 'stemAramaic.Hithpaal',
  STEM_ARAMAIC_HTPE: 'stemAramaic.Hithpeel',
  STEM_ARAMAIC_HTPO: 'stemAramaic.Hithpolel',
  STEM_ARAMAIC_ISH: 'stemAramaic.Ishtaphel',
  STEM_ARAMAIC_ITH: 'stemAramaic.Ithpoel',
  STEM_ARAMAIC_ITPA: 'stemAramaic.Ithpaal',
  STEM_ARAMAIC_ITPE: 'stemAramaic.Ithpeel',
  STEM_ARAMAIC_PAEL: 'stemAramaic.Pael',
  STEM_ARAMAIC_PEAL: 'stemAramaic.Peal',
  STEM_ARAMAIC_PEIL: 'stemAramaic.Peil',
  STEM_ARAMAIC_POL: 'stemAramaic.Polel',
  STEM_ARAMAIC_SAPH: 'stemAramaic.Saphel',
  STEM_ARAMAIC_SHAP: 'stemAramaic.Shaphel',
  STEM_HIFIL: 'stemHebrew.hifil',
  STEM_HITHPAEL: 'stemHebrew.hithpael',
  STEM_HOFAL: 'stemHebrew.hofal',
  STEM_NIFAL: 'stemHebrew.nifal',
  STEM_PIEL: 'stemHebrew.piel',
  STEM_PUAL: 'stemHebrew.pual',
  STEM_QAL: 'stemHebrew.qal',
  STEM_QAL_PASSIVE: 'stemHebrew.qalPassive',
  SUFFIX_DIRECTIONAL_HEY: 'modifier.suffixDirectionalHey',
  SUFFIX_PARAGOGIC_HEY: 'modifier.suffixParagogicHey',
  SUFFIX_PARAGOGIC_NUN: 'modifier.suffixParagogicNun',
  SUFFIX_PRONOMINAL: 'modifier.suffixPronominal',
  TENSE_2ND_FORM: 'tense.2ndForm',
  TENSE_AORIST: 'tense.aorist',
  TENSE_FUTURE: 'tense.future',
  TENSE_IMPERFECT: 'tense.imperfect',
  TENSE_NONE: 'tense.none',
  TENSE_PERFECT: 'tense.perfect',
  TENSE_PLUPERFECT: 'tense.pluperfect',
  TENSE_PRESENT: 'tense.present',
  VOICE_ACTIVE: 'voice.active',
  VOICE_MIDDLE: 'voice.middle',
  VOICE_MIDDLE_OR_PASSIVE: 'voice.middleOrPassive',
  VOICE_NONE: 'voice.none',
  VOICE_PASSIVE: 'voice.passive',
  WAW_CONSECUTIVE: 'modifier.wawConsecutive',
  Z_ADVERB_CONDITIONAL: 'pos.adverbOfCondition',
  Z_ADVERB_TIME: 'pos.adverbOfTime',
  Z_ADVERB_PLACE: 'pos.adverbOfPlace',
  Z_PARTICIPLE_ACTIVE: 'pos.participleActive',
  Z_PARTICIPLE_MIDDLE: 'pos.participleMiddle',
  Z_PARTICIPLE_PASSIVE: 'pos.participlePassive',
  Z_WAW_CONJUNCTIVE: 'modifier.wawConjunctive',
  Z_WAW_CONSECUTIVE: 'modifier.wawConsecutive',
  Z_WAW_CORRELATIVE: 'modifier.wawCorrelative',
  Z_HEY_INTERROGATIVE: 'modifier.prefixInterrogativeHey'
}
