import { BaseModel } from '../lib/model'
import { WorkHeadingListModel } from './work-heading-model'
import { QueryRowListModel } from './query-row-model'

export class QueryPassageResults extends BaseModel {
  headings;
  rows;
  encoded;
  cached;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  modelCopy (data) {
    // console.log(data)
    super.modelCopy(data)

    if (this.headings instanceof Array) {
      // console.log(this.headings)
      this.headings = new WorkHeadingListModel(this.headings)
    }

    if (this.rows instanceof Array) {
      // console.log(this.rows)
      this.rows = new QueryRowListModel(this.rows)
    }
  }
}
