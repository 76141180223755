import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import user from '@/lib/vuex-modules/user'
import breadcrumbs from '@/lib/vuex-modules/breadcrumbs'
import notifications from '../lib/vuex-modules/notifications'
import { BiblePassage } from '../classes/bible-reference'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

// var jwtDecode = require('jwt-decode')
// let siteUrl = 'http://localhost:8000';
// let clientUrl = 'http://localhost:8080';
// let clientUrl = process.env.VUE_APP_CLIENT_URL;
const siteUrl = process.env.VUE_APP_API_URL

export default new Vuex.Store({
  modules: {
    user: user,
    breadcrumbs: breadcrumbs,
    notifications: notifications
  },
  state: {
    endpoints: {
      obtainJWT: siteUrl + '/auth/obtain_token/',
      refreshJWT: siteUrl + '/auth/refresh_token/'
    },
    apiBaseUrl: siteUrl,
    siteUrl: siteUrl,
    lastPassage: localStorage.getItem('lastPassage') ? localStorage.getItem('lastPassage') : { encoded: '1.1.1-1.2' }
    // gridType: 'bootstrap'
  },
  getters: {
    lastPassage (state) {
      return new BiblePassage(state.lastPassage)
    }
  },
  mutations: {
    setLastPassage (state, passage) {
      state.lastPassage = passage
      localStorage.setItem('lastPassage', passage.encode())
    }
  },
  actions: {
  }
})
