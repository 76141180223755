import { BaseListModel } from '@/lib/model'
import slugs2tags from '../slugs2i18n.js'

export class ParsingSlugCountsModel extends BaseListModel {
    counts = {};
    tags2slugs = {};

    constructor (list) {
      super()
      this.api.url = '/parsing-slug-counts/'

      this.modelCopy(list)

      for (const key in slugs2tags) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.tags2slugs.hasOwnProperty(slugs2tags[key])) {
          this.tags2slugs[slugs2tags[key]].push(key)
        } else {
          this.tags2slugs[slugs2tags[key]] = [key]
        }
      }
    }

    modelCopy (items) {
      if (items instanceof Array) {
        this.counts = {}
        for (const item of items) {
          this.counts[item.slug] = item.cnt
          this.list.push(item)
        }
      }
    }

    getTagCount (tag) {
      // console.log(tag);
      let count = 0
      // eslint-disable-next-line no-prototype-builtins
      if (!this.tags2slugs.hasOwnProperty(tag)) {
        return null
      }
      for (const slug in this.tags2slugs[tag]) {
        // console.log(this.tags2slugs[tag][slug]);
        // eslint-disable-next-line no-prototype-builtins
        if (this.counts.hasOwnProperty(this.tags2slugs[tag][slug])) {
          count += this.counts[this.tags2slugs[tag][slug]]
        }
      }
      // console.log('tag:', tag, 'slug:', this.tags2slugs[tag], 'count:', count);
      return count
    }
}
