
export default {
  state: {
    stack: []
  },
  getters: {
    breadCrumbs: state => {
      return state.stack
    },
    breadCrumbCount: state => {
      return state.stack.length
    }
  },
  mutations: {
    pushBreadCrumb (state, crumb) {
      if (crumb.enabled === null) {
        crumb.enabled = true
      }
      state.stack.push(crumb)
    },
    popBreadCrumb (state) {
      state.stack.pop()
    },
    setBreadCrumbs (state, crumbs) {
      state.stack = crumbs
    }
  },
  actions: {
    resetBreadCrumbs: context => {
      context.commit('setBreadCrumbs', [])
    }
  }
}
