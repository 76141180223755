import BookNames from '@/data/proper-book-names.json'
import BookNums from '@/data/books-to-nums.json'
import { defined, hindi2arabic } from '../funclib'
import i18n from '../i18n'

for (const lang in BookNames) {
  for (const bookNum in BookNames[lang]) {
    BookNums[BookNames[lang][bookNum].abbrev] = bookNum
    BookNums[BookNames[lang][bookNum].title] = bookNum
  }
}

export class BibleReference {
  _book;
  _chapter;
  _verse;
  _status;

  constructor (value) {
    this.reset()
    if (typeof value === 'object') {
      this.decode(value.encoded)
    } else if (typeof value === 'string') {
      if (!this.decode(value)) {
        this.setFromText(value)
      }
    }
  }

  reset () {
    this._book = null
    this._chapter = null
    this._verse = null
    this._status = ''
  }

  book () {
    return this._book
  }

  bookName () {
    // BookNames defined in translations.js
    // i18n defined in initialize.js
    let lang
    if (!defined(BookNames[i18n.locale])) {
      if (defined(BookNames[i18n.fallbackLocale])) {
        lang = i18n.fallbackLocale
      } else {
        return null
      }
    } else {
      lang = i18n.locale
    }

    if (lang && this.book()) {
      return BookNames[i18n.locale][this.book()].title
    }
    return null
  }

  abbrevBookName () {
    if (!defined(BookNames[i18n.locale])) {
      if (defined(BookNames[i18n.fallbackLocale])) {
        return BookNames[i18n.fallbackLocale][this.book()].abbrev
      } else {
        return null
      }
    }

    return BookNames[i18n.locale][this.book()].abbrev
  }

  human () {
    const cnv = this.chapterAndVerse()
    return i18n.localize(this.bookName() + (cnv ? ' ' + cnv : ''))
  }

  humanCompact () {
    return BibleReference.compact(this.human())
  }

  abbrevHuman () {
    const cnv = this.chapterAndVerse()
    return i18n.localize(this.abbrevBookName() + (cnv ? ' ' + cnv : ''))
  }

  abbrevHumanCompact () {
    return BibleReference.compact(this.abbrevHuman())
  }

  chapterAndVerse () {
    let text = ''
    if (this.hasChapter()) {
      text += (text ? ' ' : '') + this.chapter()
    }
    if (this.hasVerse()) {
      text += (text ? ':' + (i18n.isRtl() ? ' ' : '') : '') + this.verse()
    }

    return text
  }

  static compact (refText) {
    if (i18n.isRtl()) {
      return refText.replace(': ', ':\u200f')
    }
    return refText
  }

  setBookName (name) {
    // eslint-disable-next-line no-prototype-builtins
    if (BookNums.hasOwnProperty(name.toLowerCase())) {
      this.setBook(BookNums[name.toLowerCase()])
      return true
    }

    this.setStatus(i18n.t('error.badBookName', { name: name }))
    return false
  }

  chapter () {
    return this._chapter
  }

  verse () {
    return this._verse
  }

  hasBook () {
    return defined(this._book)
  }

  hasChapter () {
    return defined(this._chapter)
  }

  hasVerse () {
    return defined(this._verse)
  }

  setBook (book) {
    this._book = +book
  }

  setChapter (chapter) {
    this._chapter = +chapter
  }

  setVerse (verse) {
    this._verse = +verse
  }

  status () {
    return this._status
  }

  setStatus (status) {
    this._status = status
  }

  clearStatus () {
    delete this._status
  }

  serialize () {
    return { encoded: this.encode() }
  }

  encode () {
    let data = '' + this.book()
    if (this.hasChapter()) {
      data += '.' + this.chapter()
      if (this.hasVerse()) {
        data += '.' + this.verse()
      }
    }

    return data
  }

  decode (text) {
    this.reset()

    const parts = text.split(/\./)

    for (let i = 0; i < parts.length; i++) {
      if (!parts[i].match(/^(\d+)$/)) {
        this.setStatus(i18n.t('error.decodingReference'))
        return false
      }
    }

    if (parts.length > 0) {
      this.setBook(parts[0])
    } else {
      this.setStatus(i18n.t('error.decodingReference'))
      return false
    }

    if (parts.length > 1) { this.setChapter(parts[1]) }

    if (parts.length > 2) { this.setVerse(parts[2]) }

    this.clearStatus()

    return true
  }

  setFromText (text) {
    text = hindi2arabic(text).trim()

    this.reset()

    if (text.match(/[-,;]/)) {
      this.setStatus(i18n.t('error.illegalCharactersInReference'))
      // console.log('reference parse error:', this.status())
      return false
    }

    // change '2 Ti' to 2Ti for parsing
    text = text.replace(/^\s*(\d)\s+([A-Za-z\u0620-\u06cf]+)/, '$1$2')

    // Arabic convention allows for writing a reference without placing a space
    // between the book name and the chapter number
    text = text.replace(/^(\d*[A-Za-z\u0620-\u06cf]+)(\d+)/, '$1 $2')

    const parts = text.split(/[\s.:\u200F]+/)

    // console.log('parts:', parts)
    for (let i = 0; i < parts.length; i++) {
      const p = parts[i].trim()

      if (p.match(/^(\d\s*|)[A-Za-z\u0620-\u06cf]{2,}$/)) {
        if (!this.setBookName(p)) {
          // console.log('reference parse error:', this.status())
          return false
        }
      } else if (p.match(/^\d+$/)) {
        if (!this.hasChapter()) {
          this.setChapter(p)
        } else {
          this.setVerse(p)
        }
      }
    }

    if (!this.hasBook() && this.hasChapter() && !this.hasVerse()) {
      this.setVerse(this._chapter)
      delete this._chapter
    }

    if (!this.isEmpty()) {
      this.clearStatus()
      return true
    }

    this.setStatus(i18n.t('error.parsingReference'))
    return false
  }

  isEmpty () {
    return !this.hasBook() && !this.hasChapter() && !this.hasVerse()
  }

  isValid () {
    return !this.isEmpty() && !defined(this._status)
  }

  hex () {
    let hex
    if (this.hasBook()) {
      hex = this.book().toString(16)
      if (hex.length === 1) {
        hex = '0' + hex
      }

      if (this.hasChapter()) {
        let num = this.chapter().toString(16)
        if (num.length === 1) {
          num = '0' + num
        }
        hex += num

        if (this.hasVerse()) {
          num = this.verse().toString(16)
          if (num.length === 1) {
            num = '0' + num
          }
          hex += num
        }
      }
    }

    return hex
  }

  setFromHex (hex) {
    this.setBook(parseInt(hex.substr(1, 2), 16))
    hex = hex.substr(3, 4)
    if (hex.length) {
      this.setChapter(parseInt(hex.substr(1, 2), 16))
      hex = hex.substr(3, 2)

      if (hex.length) {
        this.setVerse(parseInt(hex.substr(1, 2), 16))
      }
    }
  }
}

export class BiblePassage extends BibleReference {
  _endChapter;
  _endVerse;

  constructor (text) {
    super()
    this.reset()
    if (typeof text === 'string') {
      if (!this.decode(text)) {
        this.setFromText(text)
      }
    } else if (typeof text === 'object') {
      this.decode(text.encoded)
    }
  }

  reset () {
    super.reset()
    this._endChapter = null
    this._endVerse = null
  }

  setFromText (text) {
    text = hindi2arabic(text)
    this.clearStatus()
    const parts = text.trim().split(/\s*-\s*/)

    if (defined(parts[0])) {
      if (!super.setFromText(parts[0])) {
        if (!this.status()) {
          this.setStatus(i18n.t('error.parsingReference'))
        }
        return false
      }
    }

    if (defined(parts[1])) {
      let matches = parts[1].match(/^(\d+)[.:\s](\d+)\s*/)
      if (matches) {
        this.setEndChapter(matches[1])
        this.setEndVerse(matches[2])
        this.clearStatus()
        return true
      }

      matches = parts[1].match(/^(\d+)\s*/)
      if (matches) {
        console.log('match')
        if (this.hasVerse()) {
          this.setEndChapter(this.chapter())
          this.setEndVerse(matches[1])
          this.clearStatus()
          return true
        } else if (this.hasChapter()) {
          this.setEndChapter(matches[1])
          this.clearStatus()
          return true
        }
      } else {
        this.setStatus(i18n.t('error.parsingReference'))
        return false
      }
    } else {
      this.clearEndChapter()
      this.clearEndVerse()
    }

    this.clearStatus()
    return true
  }

  hasSameChapter () {
    return (!this.hasEndChapter() || this.chapter() === this.endChapter())
  }

  hasSameVerse () {
    return (!this.hasEndVerse() || this.verse() === this.endVerse())
  }

  endChapter () {
    if (!this.hasEndChapter()) {
      return this.chapter()
    }

    return this._endChapter
  }

  setEndChapter (chapter) {
    this._endChapter = +chapter
  }

  clearEndChapter () {
    delete this._endChapter
  }

  hasEndChapter () {
    return defined(this._endChapter)
  }

  endVerse () {
    if (!this.hasEndVerse()) {
      return this.verse()
    }

    return this._endVerse
  }

  setEndVerse (verse) {
    this._endVerse = +verse
  }

  clearEndVerse () {
    delete this._endVerse
  }

  hasEndVerse () {
    return defined(this._endVerse)
  }

  encode () {
    let data = super.encode()

    if (!data) {
      return data
    }

    data += '-'
    data += (this._endChapter ? this.endChapter() : this.chapter())
    data += '.'
    data += (this._endVerse ? this.endVerse() : this.verse())

    return data
  }

  decode (text) {
    this.reset()
    const parts = text.split(/-/)

    if (parts.length === 2) {
      if (!super.decode(parts[0])) {
        this.setStatus(i18n.t('error.decodingReference'))
        return false
      }

      const ends = parts[1].split(/\./)
      if (ends.length === 2) {
        if (ends[0].match(/^(\d+)$/)) {
          this.setEndChapter(ends[0])
        } else if (!ends[0].empty()) {
          this.setStatus(i18n.t('error.decodingReference'))
          // this.setStatus("unable to decode '" + ends[0] + "' in '" + text + "'")
          return false
        }

        if (ends[1].match(/^(\d+)$/)) {
          this.setEndVerse(ends[1])
        } else if (!ends[1].empty()) {
          this.setStatus(i18n.t('error.decodingReference'))
          // this.setStatus("unable to decode '" + ends[1] + "' in '" + text + "'")
          return false
        }
      } else {
        this.setStatus(i18n.t('error.decodingReference'))
        // this.setStatus("unable to decode '" + parts[1] + "' in '" + text + "'")
        return false
      }

      this.clearStatus()
      return true
    } else if (parts.length === 1) {
      return super.decode(parts[0])
    }

    this.setStatus(i18n.t('error.decodingReference'))
    return false
  }

  human () {
    return super.human() + this.endChapterAndVerse()
  }

  abbrevHuman () {
    return super.abbrevHuman() + this.endChapterAndVerse()
  }

  endChapterAndVerse () {
    let text = ''
    if (this.hasEndChapter() && this.endChapter() !== this.chapter()) {
      text += this.endChapter()
    }

    if (this.hasEndVerse() && this.endVerse() !== this.verse()) {
      text += (text ? ':' : '') + this.endVerse()
    }

    return i18n.localize((text ? '-' : '') + text)
  }

  isValid () {
    return super.isValid()
  }
}
