import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { arabic2hindi } from './funclib'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  const parsings = require.context('./biblical-data/parsings', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  parsings.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      if (messages[locale]) {
        messages[locale] = Object.assign(messages[locale], parsings(key))
      } else {
        messages[locale] = parsings(key)
      }
    }
  })
  return messages
}

export default new class extends VueI18n {
  localize (text) {
    if (this.locale === 'ar') {
      return arabic2hindi(text)
    }

    return text
  }

  isRtl () {
    return this.locale === 'ar'
  }
}({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})
