import { BaseModel } from '@/lib/model'
import { StrongsWordListModel } from '@/models/strongs-word-model'
import { StrongsMeaningListModel } from '@/models/strongs-meaning-model'
import { StrongsEntryListModel } from '@/models/strongs-entry-model'
import { QueryPassageResults } from './query-passage-results'

export class QueryStrongsResults extends BaseModel {
  strongsNum;
  workId;
  lang;
  words;
  meanings;
  entries;
  polyglot;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  modelCopy (data) {
    // console.log(data)
    super.modelCopy(data)

    if (data.polyglot) {
      this.polyglot = new QueryPassageResults(data.polyglot)
    }

    if (this.words instanceof Array) {
      // console.log(this.headings)
      this.words = new StrongsWordListModel(this.words)
    }

    if (this.meanings instanceof Array) {
      // console.log(this.rows)
      this.meanings = new StrongsMeaningListModel(this.meanings)
    }

    if (this.entries instanceof Array) {
      this.entries = new StrongsEntryListModel(this.entries)
    }
  }
}
