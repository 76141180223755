import { BaseListModel, BaseModel } from '../lib/model'
import { QueryTextRowListModel } from './query-text-row-model'
import { BibleReference } from '../classes/bible-reference'

export class QueryRowModel extends BaseModel {
  reference;
  texts;
  cached;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  modelCopy (data) {
    super.modelCopy(data)
    if (this.texts instanceof Array) {
      this.texts = new QueryTextRowListModel(this.texts)
    }
    // console.log('reference:', this.reference, typeof this.reference)
    this.reference = new BibleReference(this.reference)
  }
}

export class QueryRowListModel extends BaseListModel {
  _class = QueryRowModel

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
