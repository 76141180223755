import Vue from 'vue'
import VueRouter from 'vue-router'
import PassageView from '../views/PassageView'
import ParsingTagsView from '../biblical-data/views/ParsingTagsView'
import HebrewSearch from '../views/HebrewSearch'
import StrongsSearch from '../views/StrongsSearch'
import StrongsPage from '@/views/StrongsPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/parsing-tags',
    name: 'parsing-tags',
    component: ParsingTagsView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/',
    name: 'home',
    component: PassageView
  },
  {
    path: '/hebrew-search',
    name: 'hebrew-search',
    component: HebrewSearch
  },
  {
    path: '/strongs-search',
    name: 'strongs-search',
    component: StrongsSearch
  },
  {
    path: '/strongs-page',
    name: 'strongs-page',
    component: StrongsPage
  }
]

const router = new VueRouter({
  routes
})

export default router
