export default {
  methods: {
    trapEnter (event) {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    queryFilters (action) {
      const that = this
      this.query.api.queryFilters(action).then((response) => {
        // console.log('polyglot loaded!')
        if (that.query.polyglot.rows.length) {
          if (that.query.moreResults) {
            that.notifyWarning(that.$t('notify.partialResults'))
          } else {
            that.notifySuccess(that.$t('notify.passageLoaded'))
          }
        } else {
          // if there are no rows then the verse reference was bad
          that.notifyError(that.$t('notify.badReference'))
          // that.notifyError('No Rows!')
        }
      }).catch((e) => {
        console.log(e)
        that.notifyError(that.$t('notify.passageFailedToLoad'))
      })
    },
    getPrevious () {
      this.queryFilters('previous')
    },
    getNext () {
      this.queryFilters('next')
    },
    resultsHeading () {
      return this.$t('title.searchResults', {
        from: this.query.session.offset() + 1,
        to: this.query.session.offset() + this.query.session.hits().length,
        total: this.query.session.occurrences
      })
    }
  }
}
