<template>
  <v-tooltip
    bottom
  >
    <template v-slot:activator="{ on }"><span v-on="on" :lang="$i18n.locale"><slot></slot></span></template>
    <span :lang="$i18n.locale">{{ tip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TippedText',
  props: {
    tip: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
