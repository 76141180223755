import { BaseListModel, BaseModel } from '../lib/model'
import { WordListModel } from './word-model'
import { BibleReference } from '../classes/bible-reference'

export class QueryTextRowModel extends BaseModel {
  workId;
  words;
  lang;
  reference;
  atStart;
  cached;

  constructor (data) {
    super()
    this.modelCopy(data)
  }

  modelCopy (data) {
    if (data instanceof Object) {
      super.modelCopy(data)
      if (this.words instanceof Array) {
        this.words = new WordListModel(this.words)
      }
      if (this.reference) {
        this.reference = new BibleReference(this.reference)
      }
    }
  }
}

export class QueryTextRowListModel extends BaseListModel {
  _class = QueryTextRowModel;

  constructor (data) {
    super()
    this.modelCopy(data)
  }
}
