import { Remarkable } from 'remarkable'

// eslint-disable-next-line camelcase
const hindi_nums = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

export function arabic2hindi (num) {
  const str = num + ''
  return str.replace(/([0-9])/g, function (n) { return hindi_nums[n] })
}

export function joinUrls () {
  if (!arguments.length) { return null }
  let url = String(arguments[0])
  for (let i = 1; i < arguments.length; i++) {
    if (arguments[i]) { url = _joinUrls(url, String(arguments[i])) }
  }

  return url
}

function _joinUrls (url1, url2) {
  function arrayTrim (urls) {
    if (urls.length > 0 && urls[0] === '') {
      urls.shift()
    }
    if (urls.length > 0 && urls.slice(-1)[0] === '') {
      urls.pop()
    }
    return urls
  }

  let urls = arrayTrim(url1.split('/'))
  urls = urls.concat(arrayTrim(url2.split('/')))

  return urls.join('/') + '/'
}

class Script {
  code = null;
  brackets = '\\(\\)\\[\\]\\{\\}';
  quotes = '\'"`';
  characters = null;
  punctuation = null;
  _re = RegExp('');

  initRegExp () {
    const chars = this.characters
    const punc = this.punctuation
    const quotes = this.quotes
    const brackets = this.brackets

    // replace '.' with [\s\S] since the 's' modifier is not working in my Javascript.
    // The 's' modifier is in ES2018
    this._re = new RegExp(`^([\\s\\S]*?)([${this.characters}]([${chars}${punc}${quotes}${brackets}\\s]*[${chars}]|))([\\s\\S]*?)$`)
  }

  parseText (text) {
    const result = this._re.exec(text)

    if (result) {
      return {
        before: result[1],
        text: result[2],
        after: result[4],
        script: this.code
      }
    }

    return null
  }

  parseTextIntoBlocks (text) {
    let blocks = []
    const result = this.parseText(text)
    if (result) {
      if (result.before.length) {
        blocks = blocks.concat(this.parseTextIntoBlocks(result.before))
      }

      blocks.push({
        script: this.code,
        text: result.text
      })

      if (result.after.length) {
        blocks = blocks.concat(this.parseTextIntoBlocks(result.after))
      }
    } else {
      blocks = [
        {
          script: null,
          text: text
        }
      ]
    }
    return blocks
  }

  parseScriptBlocks (blocks) {
    let results = []
    for (const block of blocks) {
      if (block.script === null) {
        results = results.concat(this.parseTextIntoBlocks(block.text))
      } else {
        results.push(block)
      }
    }

    return results
  }
}

export class ScriptHebrew extends Script {
  code = 'HE';
  characters = '\u{0590}-\u{05ff}\u{fb1d}-\u{fb4f}';
  static vowels = '\u{0591}-\u05BD\u05BF-\u05C2\u05C4-\u05C7';
  punctuation = '\\./:!@#$٪\\^&\\*';

  constructor () {
    super()
    this.initRegExp()
  }

  static startsWithVowel (text) {
    return text.search(RegExp(`^[${this.vowels}]`)) !== -1
  }
}

export class ScriptArabic extends Script {
  code = 'AR';
  characters = '\u{0600}-\u{06FF}\u{0750}-\u{077F}\u{08A0}-\u{08FF}\u{FB50}-\u{FDFF}\u{FE70}-\u{FEFF}';
  punctuation = '\\.،\\/:"!@#$٪\\^&1234567890\\*';

  constructor () {
    super()
    this.initRegExp()
  }
}

const SCRIPTS = [
  new ScriptArabic(),
  new ScriptHebrew()
]

export function getLanguage () {
  return localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar'
}

export function markScripts (text, excludeScript) {
  let blocks = [
    {
      script: null,
      text: text
    }
  ]
  for (const script of SCRIPTS) {
    // eslint-disable-next-line no-console
    if (script.code !== excludeScript) {
      blocks = script.parseScriptBlocks(blocks)
    }
  }

  let html = ''
  for (const block of blocks) {
    if (block.script) {
      html += `<span lang="${block.script}" class="lang-${block.script}">${block.text}</span>`
    } else {
      html += block.text
    }
  }

  return html
}

const _md = new Remarkable({
  html: true,
  xhtmlOut: true,
  breaks: true
})

export function markdownToHtml (markdown, baseLang) {
  return _md.render(markScripts(markdown, baseLang))
}
